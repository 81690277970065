
import React from 'react'
import { Button, Modal, Stack, Typography } from "@mui/material";
import ProductList from "./ProductList"
import Loading from "./Loading"
import Container  from "@mui/material/Container";


    
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
};
 


export default function SubscriptionPopup({handleCallback, handleClose, products, postage}) {


    const [loadingUrl, setLoadingUrl] = React.useState(false)

    const onClose = () => {

        handleClose()
    }
    
    
    const onSelected = (updates) =>{


        handleCallback(updates)
        setLoadingUrl(true)
        onClose()
    
    }
    

    

    return(
        <Container maxWidth='sm' disableGutters={true} sx={style}>
            {loadingUrl && <Loading text="Loading Payment "/>}
             <Stack direction='row' justifyContent='space-between' padding="5px">
                <Typography variant='h5'>{'Start my subscription'}</Typography>
                <Button variant='outlined' onClick={onClose}>close</Button>
            </Stack>
            {!loadingUrl && <ProductList data={products} postage={postage} handleSelected={(updates) => onSelected(updates)} />}
        </Container>
    );

}


