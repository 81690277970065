import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import { Card, Container, Typography, Box } from "@mui/material";
import { useFirebase } from "../../FirebaseApp";
import { useNote } from "../../hooks/useNote";
import { VerticalSpacing } from "../Common/Common";
import { TopNavigationBar } from "../Common/TopNavigationBar";
import bgImage from "../../Assets/girl_piano_bw.webp"


function FAQ() {
    
  const {user, loading} = useFirebase()
  const [faqs, setFaqs] = useState([])
  const { getFAQs } = useNote()
  const navigate = useNavigate();

  useEffect(() => {
                                                   
    if (loading) return;
    if (!user) return navigate("/welcome", {replace:true});
    
    if(faqs == null || faqs.length < 1){
      getAndSetFAQ()
    }
      
  }, [user, loading, faqs]);


  const getAndSetFAQ = async() => {
    let f = await getFAQs()
    setFaqs(f)

  }

  const FAQItem = ({item}) => {
    return(
      <Card sx={{padding:"2px", marginBlock:"15px"}}>
        <Typography variant="h6">{item.title}</Typography>
        <VerticalSpacing value="5px"/>
        <Typography variant="subtitle2">{item.description}</Typography>            
      </Card>)
  }

  const FAQList = ({items}) =>{
    const final = []
    for(let faq of items){

      if(faq !== undefined){
          final.push(<FAQItem item={faq}/>);
      } 
    }

    return (
      <Box>
        {final}
      </Box>

    )
  }

                                 
  return (
    <Container className="main-container" maxWidth={false} sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: "fixed",
        maxHeight:'100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
       { user && <TopNavigationBar/>}    
        <Container maxWidth='sm' style={{ background: '#ffffff', padding:'10px', borderRadius: 2 }}>
            <Typography variant="h5">FAQs</Typography>            
            <VerticalSpacing value="15px"/>
            <FAQList items={faqs}/>      
        </Container>
    </Container>
  );
}

  
export default FAQ;  