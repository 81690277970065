
function UserModel(data){
    let user = {

    finishedIntroduction:false, 
    authProvider : "email",
    created : new Date(),
    email : "",
    uid : "",
    name : "",
    companyName:"",
    companyId:""

}
    
  if(data !== undefined){

      
        if(data.authProvider !== undefined){
            user.authProvider = data.authProvider
        }
      
        if(data.created !== undefined){
            user.created = data.created
        }
      
        if(data.email !== undefined){
            user.email = data.email
        }   
      
        if(data.finishedIntroduction !== undefined){
            user.finishedIntroduction = data.finishedIntroduction
        }
      
        if(data.uid !== undefined){
            user.uid = data.uid
        }  
      
        if(data.name !== undefined){
            user.name = data.name
        }  
      
        if(data.companyId !== undefined){
            user.companyId = data.companyId
        } 
      
        if(data.companyName !== undefined){
            user.companyName = data.companyName
        } 
    }
    
    return user;

};


export default UserModel;