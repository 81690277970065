import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {useFirebase} from "../../FirebaseApp";
import { useNote } from "../../hooks/useNote";
import  Container  from "@mui/material/Container";
import SupportPopupView from "../Common/SupportPopupView"
import { Link, Switch, Snackbar, Alert, Button, Card, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography, Box } from "@mui/material";
import { ThinLine, VerticalSpacing } from "../Common/Common";
import { TopNavigationBar } from "../Common/TopNavigationBar";
import bgImage from "../../Assets/girl_piano_bw.webp"



function Contact() {
    
  const {user,  logout} = useFirebase()
  const {userData, organization, loading,  submitSupportRequest} = useNote();
    
  const noResults = {
    success: null,
    error:null
  };
  const [message, setMessage] = React.useState("")
  const [email , setEmail] = React.useState("")

  const [firstRun, setFirstRun] = React.useState(true)
  const [actionResults, setActionResults] = React.useState(noResults);
  const [messageSent, setMessageSent] = useState(false)

  const navigate = useNavigate();

    

    useEffect(() => {
        
        if(loading) {return;}
        if (!user) {return navigate("/welcome", {replace:true});}

        if(firstRun){
          setFirstRun(false)

          setDefaultEmailValue()

        }

    }, [user, userData, organization, actionResults, firstRun, messageSent]);
    
    function handleSnackbarClose(){
        setActionResults(noResults);
    }
    
    function snackbarOpen(){
        return (Boolean(actionResults.success) || Boolean(actionResults.error))
    }

    
    const openFAQ = () =>{
        navigate("/faq", {replace:false})

    }  
    const openHowTo = () =>{
        navigate("/intro", {replace:false})

    }  
    
    const textChanged = (text) =>{
        setMessage(text)
    }
    
    const emailChanged = (text) =>{
        setEmail(text)
    }
  

                                          
                                                          
    const handleSupportRequest = async() => {
    
        var request = { uid:userData.uid, email:email, name: userData.name, message:message}
        const result = await submitSupportRequest(request)

        if(!result || !result.success){
            let message = result.message
            const newString = message.split("Firebase:").pop();
            setActionResults({
              error:newString,
              success: null
            });

          }else{
            setActionResults({
              error:null,
              success: 'Success, Support Request Sent!'
            });
            setMessageSent(true)
          }                          
    }


    const setDefaultEmailValue = () => {

        let defaultE = ""
        if(user != null){
          defaultE = user.email
          
        }
        setEmail(defaultE)
    }

                                          
    return (
                                                      
        <Container className="main-container" maxWidth={false} sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: "fixed",
          maxHeight:'100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
            { user && <TopNavigationBar/>}    
            <Container maxWidth='sm' style={{ background: '#ffffff', paddingLeft:'10px', paddingRight:'10px', paddingTop:'10px', borderRadius: 2 }}>
                <VerticalSpacing value='10px'/>
                <Box>
                      <VerticalSpacing value='10px'/>
                                                            
                      <Typography sx={{fontWeight:'bold'}} variant="h5">Contact Us</Typography>
                      <VerticalSpacing value='10px'/>

                      <Typography  variant="h6">Take a moment to look at how NOTE works and the FAQs</Typography>

                      <Stack direction='row' spacing={3} sx={{padding:'5px', justifyContent:'left'}}>
                          <Button variant="contained"  onClick={() => openHowTo()}>How NOTE Works</Button>
    
                          <Button variant="outlined"  onClick={() => openFAQ()}>FAQs</Button>
                      </Stack>


                </Box>

                <VerticalSpacing value='40px'/>

                {!messageSent && <Typography  variant="body">If you still have questions or concerns, send us a message</Typography>}
                    
                {messageSent ? 
                  <Stack  direction='column'>
                      <Typography variant="h4">Thank you for your Message!</Typography>
                      <Typography variant="body">We'll get back to you as soon as possible</Typography>
                  </Stack>
                  :
                  <Box sx={{ width:'100%', padding:'5px'}} justifyContent='end'>
          
                      <VerticalSpacing value='10px'/>

                        <TextField 
                          value={email}
                          onChange={(e) => emailChanged(e.target.value)}
                          inputProps={{maxLength:100}}
                          fullWidth
                          label='Email'
                        />   
                        <VerticalSpacing value='20px'/>
          
                        <TextField
                          rows={7}
                          inputProps={{maxLength:500}}
                          fullWidth
                          height="200px"
                          label='Message'
                          multiline
                          placeholder="Enter Message (Max 500 characters)"
                          onChange={(e) => textChanged(e.target.value)}
                        />
                        <VerticalSpacing value='20px'/>
                        <Button variant="contained" disabled={message === "" || email === ""} onClick={() => handleSupportRequest()}>Send</Button>
                              
                  </Box>
                }

                <VerticalSpacing value={20}/>
            </Container>
    
    
            


          <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
          {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
            {actionResults.success ? actionResults.success : actionResults.error}
          </Alert> : <div/>}

          </Snackbar>

        </Container>
    );
};

export default Contact;