import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import bgImage from "../../Assets/girl_piano_bw.webp"

import SendOneCardPopup from "../Common/SendOneCardPopup"
import ConfirmationPopup from "../Common/ConfirmationPopup";

import AsyncImage from "../Common/AsyncImage"
import { Modal, Button, Card, Container, Stack, Typography, Stepper, Step, StepLabel, Box } from "@mui/material";
import { VerticalSpacing } from "../Common/Common";
import icon from "../../Assets/logo_transparent_background.png"
import timeline_entry_image from "../../Assets/timeline_entry.webp"
import call_scheduled_image from "../../Assets/call_scheduled.webp"
import template_phone_call_image from "../../Assets/template_phone_call.webp"
import send_phone_call_image from "../../Assets/send_phone_call.webp"
import send_custom_message_image from "../../Assets/send_custom_message.webp"
import automate_new_clients from "../../Assets/automate_turn_on.webp"
import automate_on from "../../Assets/automate_on.webp"
import { useFirebase } from "../../FirebaseApp";
import { TopNavigationBar } from "../Common/TopNavigationBar";
import { useNote } from "../../hooks/useNote";

function Introduction() {
    
    const { user } = useFirebase()

    const { organization, sendFreeCardOnce} = useNote()
    const [confirmationTitle, setConfirmationTitle] = useState("Success")
    const [confirmationBody, setConfirmationBody] = useState("Card Sent")
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showSendFreeCardPopup, setShowSendFreeCardPopup] = useState(false)



    const handleSendFreeCard = async(message) => {

      let updates = {}
      updates.companyId = organization.companyId
      updates.message = message
        
      let res = await sendFreeCardOnce(updates)
      
      if(res === true){
          
          setConfirmationTitle("Success")  
          setConfirmationBody("Free Card Sent")  
      }else{
          
          setConfirmationTitle("Oops")  
          setConfirmationBody("Something went wrong")  
      }

      setShowSendFreeCardPopup(false)
      setShowConfirmation(true)
    }



    return (
                                                      
        <Container maxWidth={false} disableGutters sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: "fixed",
            maxHeight:'100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
            { user && <TopNavigationBar/>}                       
            <Container maxWidth='sm' style={{ background: '#ffffff', padding:'10px', borderRadius: 2 }}>
        
                <Card elevation={0} sx={{marginTop:'100px', paddingTop:'10px', paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}}>
                  <Typography  sx={{fontWeight:'bold'}} variant="h4">{"How does NOTE work?"}</Typography>
                  <Typography   variant="body">{"Once you link your Gazelle account, everything else is done from Gazelle and you can check back here as you need. Easily manage templates in either Gazelle or NOTES, automatically send cards to all new clients as soon as you add them to Gazelle (optional), create your own custom templates to save time, and personalize your cards to make a notable and personal connection with your clients. "}</Typography>
                </Card>

               
                <Card sx={{marginTop:'50px', paddingTop:'10px', paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}}>
                    <Typography  sx={{fontWeight:'bold'}} variant="h6">{"1. Select any client in Gazelle"}</Typography>
                    <Typography variant="body2"> {"You can send notes to any client in Gazelle. "}</Typography>
                    <VerticalSpacing value={10}/>
                    <AsyncImage height='auto' width='100%' draggable="false"  src={send_custom_message_image} alt="Send Custom message"/>  
                </Card>
    

                <Card sx={{marginTop:'50px', paddingTop:'10px', paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}}>
                  <Typography  sx={{fontWeight:'bold'}} variant="h6">{"2. Send a custom message."}</Typography>
                  <Typography variant="body2">{"Use Gazelle’s custom templates to save time. We discovered a way to turn Gazelle’s phone call templates into handwritten cards that get automatically mailed to the customer. "}</Typography>
                  <VerticalSpacing value={10}/>
                  <AsyncImage height='auto' width='100%' draggable="false"  src={template_phone_call_image} alt="Send Custom message"/> 
                </Card>


                <Card sx={{marginTop:'50px', paddingTop:'10px', paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}}>
                  <Typography  sx={{fontWeight:'bold'}} variant="h6">{"3.  Personalize each message in Gazelle or use your default text."}</Typography>
                  <Typography variant="body2"> {"Use your default custom template message in Gazelle or personalize it for any client on the fly."} </Typography>
                  <VerticalSpacing value={10}/>
                  <AsyncImage height='auto' width='100%' draggable="false"  src={send_phone_call_image} alt="Send Custom message"/>  
                </Card>


                <Card sx={{marginTop:'50px', paddingTop:'10px', paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}}>
                  <Typography  sx={{fontWeight:'bold'}} variant="h6">{"4. NOTES will automatically convert your message into a handwritten card and drop it in the mail tomorrow morning. "}</Typography>
                  <Typography variant="body2"> {"All cards will be dropped in the mail the next day and usually arrive to your client within 2-5 business days. "} </Typography>
                  <AsyncImage height='auto' width='100%' draggable="false"  src={call_scheduled_image} alt="Send Custom message"/>  
                  

                </Card>

                <Card sx={{marginTop:'50px', paddingTop:'10px', paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}}>
                  <Typography  sx={{fontWeight:'bold'}} variant="h6">{"5.  Everything you need will be in the Gazelle client timeline."}</Typography>
                  <Typography variant="body2"> {"In Gazelle, NOTES will record each action we take and log everything in the Gazelle client timeline. You can track the progress of each note either in Gazelle or by logging into your NOTES account. "} </Typography>
                  <VerticalSpacing value={10}/>

                  <AsyncImage height='auto' width='100%' draggable="false"  src={timeline_entry_image} alt="Send Custom message"/> 
                
                </Card>


                <VerticalSpacing value="50px"/>
                <Typography  sx={{fontWeight:'bold'}} variant="h4">{"Automating New Client Cards"}</Typography>
                <Typography   variant="h6">{"You can also send automatic welcome cards to every new client added to Gazelle. "}</Typography>

               
                <Card sx={{marginTop:'50px', paddingTop:'10px', paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}}>
                    <Typography  sx={{fontWeight:'bold'}} variant="h6">{"1. Enable Automated Notes"}</Typography>
                    <Typography style={{whiteSpace: 'pre-line'}} variant="body2"> {"Go to your NOTES account > Card Templates > and toggle the \'Automatically send cards to new clients\' option."}</Typography>
                    <VerticalSpacing value={20}/>
                    
                    <Typography style={{whiteSpace: 'pre-line', fontWeight:'bold'}} variant="body2"> {"How automated NOTES works for new clients"}</Typography>
                    <Typography style={{whiteSpace: 'pre-line'}} variant="subtitle"> {"Enabling this will send cards to all newly created gazelle clients that have the status \'Active\' or \'New\' and have a valid address. Clients with the status \'Prospect\' are not sent cards until they become Active or New. The card template 'NOTE Automated New Client Card' will be used. You can edit this template from the Card Templates page."}</Typography>
                    <VerticalSpacing value={10}/>
                    <AsyncImage height='auto' width='100%' draggable="false"  src={automate_new_clients} alt="Automate New Client Cards"/>  
                </Card>
    
                <Card sx={{marginTop:'50px', paddingTop:'10px', paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}}>
                    <Typography  sx={{fontWeight:'bold'}} variant="h6">{"2. Edit the Automate Template"}</Typography>
                    <Typography variant="body2"> {"In NOTES from the ‘Card Template’ page you can customize what this ‘Automatic cards for new clients’ template says and use any of the Gazelle merge codes provided. After this you are all set. "}</Typography>
                    <VerticalSpacing value={10}/>
                    <AsyncImage height='auto' width='100%' draggable="false"  src={automate_on} alt="Automate On"/>  
     
                </Card>

                <VerticalSpacing value="200px"/>

            </Container>

            <Modal
              open={showSendFreeCardPopup}
              onClose={() => setShowSendFreeCardPopup(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >

              <SendOneCardPopup 
                handleUpdate={(updates) => handleSendFreeCard(updates)}
                userData={organization}
              />

            </Modal>



            <Modal
              open={showConfirmation}
              onClose={() => setShowConfirmation(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
            <ConfirmationPopup 
                show={showConfirmation}
                positive={"Close"}
                title={confirmationTitle}
                body={confirmationBody}
                handleClose={() => setShowConfirmation(false)}
                handlePositive={() => setShowConfirmation(false)}
              />
          </Modal>

        </Container>
    );
};

export default Introduction;