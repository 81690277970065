import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFirebase } from "../../FirebaseApp";
import {useNote} from '../../hooks/useNote'
import AsyncImage from "../Common/AsyncImage";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import icon from "../../Assets/logo_transparent_background.png"
import { VerticalSpacing } from "../Common/Common";
import bgImage from "../../Assets/girl_piano_bw.webp"

function UpdateEmail() {

  const { user } = useFirebase()
  const {updateUserEmail} = useNote()
  const [email, setEmail] = useState("");

  const [sent, setSent] = React.useState(false)

  const navigate = useNavigate();

  const noResults = {
    success: null,
    error:null
  };

  

  const [actionResults, setActionResults] = React.useState(noResults);

  useEffect(() => {
  }, [user, actionResults]);


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      );
  };

  const handleSubmit = async (event) => {
    
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let email = data.get('email')

    let valid = validateEmail(email)

    if(!valid){
      setActionResults({
        error:"Email is Invalid",
        success: null
      });
      return
    }

    let result = await updateUserEmail(email)

  
    if(!result || !result.success){
      let message = result.message
      const newString = message.split("Firebase:").pop();
      setActionResults({
        error:newString,
        success: null
      });
    }else{
      setActionResults({
        error:null,
        success: 'Success!'
      });
      setEmail(email)
      setSent(true)

    }

  };

  function handleSnackbarClose(){
    setActionResults(noResults);
  }

  function snackbarOpen(){
    return (Boolean(actionResults.success) || Boolean(actionResults.error))
  }

  

  return(
      <Grid container component="main" sx={{ height: '100vh' }}>

        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
          {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
            {actionResults.success ? actionResults.success : actionResults.error}
          </Alert> : <div/>}

        </Snackbar>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square="true">
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >
            
            <AsyncImage height='auto' width='350px' draggable="false"  src={icon} alt="NOTE Logo"/>    

            <Typography component="h1" variant="h5">
              Update Email
            </Typography>
            {!sent ? <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>


              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="New Email"
                name="email"
                autoComplete="email"
              />
 

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Change Email
              </Button>

              <Grid container direction='column'>
                <Grid item>
                  <Link sx={{cursor:'pointer'}} onClick={() => navigate('/register')} variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box> : 
              <Stack> 
                <Typography variant="body2">
                  {"Password Reset Email sent to " + email}
                </Typography>

                <Link sx={{cursor:'pointer'}} onClick={() => navigate('/login', {replace:false})} variant="body2">
                    {"Back to Login"}
                  </Link>

              </Stack>
            
            }
          </Box>

          <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
          {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
            {actionResults.success ? actionResults.success : actionResults.error}
          </Alert> : <div/>}

          </Snackbar>
        </Grid>

      </Grid>
  );
  

}

export default UpdateEmail;
