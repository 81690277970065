import { Container, Stack, Typography, Button, Card  } from '@mui/material';
import React from 'react';



const ProductCell = ({onSelected, data, postage}) => {
    
    
    let name = data.name
    let description = data.description
    let price = data.base_cost
    let product_id = data.product_id
    let price_id = data.price_id
    let additional_cards = data.additional_cards
    
    const test_domain = "https://test.jhersh.dev"
    const prod_domain = "https://painonotes.app"
    const local_domain = "https://localhost:4242"
    
    let currentPostage = "Current postage: $" + postage + " per card"
    
    // var purchase_button_text = "Purchase   $" + price + ' per Card'
    // if(data.type === 'subscription' || data.type === 'metered_subscription'){
    //     purchase_button_text = purchase_button_text
    // }else if(data.type === 'product'){
    //     purchase_button_text = purchase_button_text + ' credit'
    // }

    return(
            <Stack direction='column' spacing={2} sx={{ padding:'5px'}} key={product_id}>
            
                {/* <Typography variant='h6'>{name}</Typography> */}

                <Typography variant='subtitle2'>{description}</Typography>
                {/* <Typography variant='subtitle2'>{currentPostage}</Typography> */}

                <Button variant='contained' onClick={() => onSelected(data)}>{ "Purchase Subscription" }</Button>
            
            </Stack>
    ) 
}



const ProductList = ({data, handleSelected, postage}) => {
    
    const final = [];
    
    
    
    for(let product of data){

        if(product !== undefined && product.status === "public"){
            final.push(<ProductCell data={product} onSelected={(updates) => handleSelected(updates)} postage={postage}/>);
        } 
    }
    
    return (
    
        <Container maxWidth='sm'>
            {final}
        </Container>
    )
    
}



export default ProductList;