import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AsyncImage from "../Common/AsyncImage";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { InputAdornment, IconButton, Link, Paper, Box, Grid, Typography } from "@mui/material";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import icon from "../../Assets/logo_transparent_background.png"
import { Copyright } from "../Common/Common";
import {useFirebase} from "../../FirebaseApp"
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import bgImage from "../../Assets/girl_piano_bw.webp"




export default function Login() {

  const noResults = {
    success: null,
    error:null
  };


  const { user, loading, signInWith} = useFirebase()
  const [actionResults, setActionResults] = React.useState(noResults);

  const navigate = useNavigate();
  const [showPassword, setShowPassord] = React.useState(false)
  
  useEffect(() => {
      
    
    if (user) {
      navigate("/home", {replace:true}); 
    }
      
  }, [user, loading, actionResults]);



  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let result = await signInWith(data.get('email'), data.get('password'))

    if(!result.success){
      let message = result.message
      const newString = message.split("Firebase:").pop();

      setActionResults({
        error:newString,
        success: null
      });
    }else{
      setActionResults({
        error:null,
        success: 'Success!'
      });
    }

  };


  function handleSnackbarClose(){
    setActionResults(noResults);
  }

  function snackbarOpen(){
    return (Boolean(actionResults.success) || Boolean(actionResults.error))
  }

  function toggleShowPassword(){
    setShowPassord(!showPassword)
  }

  return (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >


        <Box sx={{
              backgroundColor: "#fff",
              color: "#000",
              opacity: ".6",
          }}>
            <Typography align="center" variant="h2"> You only get one first impression</Typography>
            <Typography align="right" variant="h5">Let it be remembered for a lifetime</Typography>
          </Box>
        </Grid>
        <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
          {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
            {actionResults.success ? actionResults.success : actionResults.error}
          </Alert> : <div/>}

        </Snackbar>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square="true">
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >
            
            <AsyncImage height='auto' width='350px' draggable="false"  src={icon} alt="NOTE Logo"/>    

            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />


              <TextField

                margin="normal"
                
                name="password"
                  label="Password"
                  id="password"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowPassword}
                          onMouseDown={(e) => {
                            e.preventDefault();
                          }}
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
              />

              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container direction='column'>
                <Grid item xs>
                  <Link  sx={{cursor:'pointer'}} onClick={() => navigate("/reset", {replace:false})} variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link  sx={{cursor:'pointer'}} onClick={() => navigate('/register', {replace:false})} variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
                <Grid item>
                  <Copyright/>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
  );
}

