import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {useFirebase} from "../../FirebaseApp";
import { useNote } from "../../hooks/useNote";
import  Container  from "@mui/material/Container";
import SupportPopupView from "../Common/SupportPopupView"
import { Link, Switch, Snackbar, Alert, Button, Card, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography, Box } from "@mui/material";
import { ThinLine, VerticalSpacing } from "../Common/Common";
import { TopNavigationBar } from "../Common/TopNavigationBar";
import bgImage from "../../Assets/girl_piano_bw.webp"



function Account() {
    
  const {user,  logout} = useFirebase()
  const {userData, organization, loading, getOrganization,  getUserData, updateOrganization, submitSupportRequest, updateUserData} = useNote();
    
  const noResults = {
    success: null,
    error:null
  };

  

  const [actionResults, setActionResults] = React.useState(noResults);
  const [email, setEmail] = useState("")
  const [emailSummary, setEmailSummary] = useState(true)
  const [saving, setSaving] = useState(false)

  const [fullName, setFullName] = useState('')
    
  const [streetAddress, setStreetAddress] = useState("");
  const [state, setState] = useState("Alabama");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [appt, setAppt] = useState("");
  const [senderFullName, setSenderFullName] = useState("");
  const [infoChanged, setInfoChanged] = useState("");

  const [accountRetrieved, setAccountRetrieved] = useState(false);
    
  const [showSupport, setShowSupport] = useState(false)

  
  const navigate = useNavigate();

    
  const all_states = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];


    useEffect(() => {
        
        if(loading) {return;}
        if (!user) {return navigate("/welcome", {replace:true});}

        
        if(organization != null){
            
            setStreetAddress(organization.streetAddress)
            setState(organization.state)
            setCity(organization.city)
            setZipcode(organization.zipcode)
            setSenderFullName(organization.senderFullName)
            setStreetAddress(organization.streetAddress)
            setAppt(organization.appt)
            setEmail(organization.email)

            if(organization.email === undefined || organization.email === null || organization.email === ""){
                setEmailSummary(false)

            }else{
                setEmailSummary(organization.sendEmailSummary)

            }
        }

        if(userData == null){
            getAndSetUserData()
        }

        if(organization == null && userData != null){
            if(userData.companyId !== ""){
                getOrganization(userData.companyId)
            
            }
        }


    }, [user, userData, organization, actionResults]);
    
    function handleSnackbarClose(){
        setActionResults(noResults);
      }
    
      function snackbarOpen(){
        return (Boolean(actionResults.success) || Boolean(actionResults.error))
      }
    
    const logoutUser = async() => {

        await logout()
        navigate("/welcome", {replace:true})
    }


    const getAndSetUserData = async() =>{
        let userD = await getUserData(user.uid)
        setFullName(userD.name)

    }
        

    const updateUserName = async() =>{

        if(userData == null){
            return false
        }

        if(userData.name !== fullName){
            //update user name here
            let res = await updateUserData({name:fullName}, user.uid)
            return res
        }

        return false
    }
    
    const saveNewInfo = async () => {
        
        //Write data to Firebase
        setSaving(true)  
        let error = false                                 
        let updates = {}
        updates.companyId = userData.companyId
                
        if(streetAddress !== undefined && streetAddress !== ""){
            updates.streetAddress = streetAddress
        }else{
            setActionResults({
                success:"Saved. Your cards will not send until the Address field is filled",
                error: null
              });
              error = true
        }

        if(city !== undefined && city !== ""){
            updates.city = city
        }else{
            setActionResults({
                error:null,
                success:"Saved. Your cards will not send until the City field is filled"
              });
              error = true
        }

        if(zipcode !== undefined && zipcode !== ""){
            updates.zipcode = zipcode
        }else{
            setActionResults({
                error:null,
                success:"Saved. Your cards will not send until the Zipcode field is filled"
              });
              error = true
        }


        if(senderFullName !== undefined && senderFullName !== ""){
            updates.senderFullName = senderFullName
        }else{
            setActionResults({
                error:null,
                success: "Saved. Your cards will not send until the Sender Name field is filled"
              });
              error = true
        }

        if(state !== undefined && state !== ""){
            updates.state = state
        }else{
            setActionResults({
                error:null,
                success: "Saved. Your cards will not send until the State field is filled"
              });
              error = true
        }
        if(email !== undefined && email !== ""){
            updates.email = email
        }
        
        if(userData.sendEmailSummary !== emailSummary){
            updates.sendEmailSummary = emailSummary
        }



        let res = false
        if(userData.companyId !== "" && userData.companyId !== undefined && userData.companyId !== null){
            await updateUserName()
            res = await updateOrganization(updates)
        }else {
            error = false
            res = await updateUserName()
        }
        
        
        if(res === true){

            if(!error){
                setActionResults({
                    error:null,
                    success: "Updates Successfully Saved"
                  });
            }

        }else{
            setActionResults({
                error:"Failed to Update",
                success: null
              });
        }
        

        setSaving(false)
        setInfoChanged(false)
    }
                                                      

  
    const setNewState = (nState) =>{

        setState(nState)
        setInfoChanged(true)
    } 
    
    
    const setNewEmail = (nEmail) =>{

        setEmail(nEmail)
        setInfoChanged(true)
    }



    const setSendEmailSummary = () =>{

        if(email === undefined || email === null || email === ""){
            setEmailSummary(false)
        }else{
            setEmailSummary(!emailSummary)
        }

        setInfoChanged(true)
    }


    const setNewCity = (city) =>{

        setCity(city)
        setInfoChanged(true)
    }
  
    const setNewStreetAddress = (address) => {
            
        
        setStreetAddress(address)
        setInfoChanged(true)
    
    }
    
    const setNewSenderName = (name) =>{
            
        setSenderFullName(name)
        setInfoChanged(true)
        
    
    }
  
    const setNewZipcode = (zip) => {
        setZipcode(zip)
        setInfoChanged(true)

    }


    const setNewFullName = (name) =>{
        setFullName(name)
        setInfoChanged(true)
    }
    
    const openFAQ = () =>{
        navigate("/faq", {replace:false})

    }  
    const openContactUs = () =>{
        navigate("/contact", {replace:false})

    }  
    
                                                    
                                                          
    const handleSupportRequest = async(req) => {

    
        const result = await submitSupportRequest(req)

        if(!result || !result.success){
            let message = result.message
            const newString = message.split("Firebase:").pop();
            setActionResults({
              error:newString,
              success: null
            });
            setShowSupport(false)

          }else{
            setActionResults({
              error:null,
              success: 'Success, Support Request Sent!'
            });
            setShowSupport(false)
          }                          
    }  


    const handleStateSelected  = (event) => {
        setNewState(event.target.value);
    };
                                                      
    return (
                                                      
        <Container className="main-container" maxWidth={false} sx={{
            padding:"10px",
              backgroundImage: `url(${bgImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: "fixed",
              maxHeight:'100%',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
            { user && <TopNavigationBar/>}    
            <Container maxWidth='sm' style={{ background: '#ffffff', paddingBottom:'100px', paddingTop:'100px',paddingRight:'10px', paddingLeft:'10px', borderRadius: 2 }}>
                { userData && userData.companyId !== "" && <Box>
                    <VerticalSpacing value='10px'/>
                                                           
                    <Typography sx={{fontWeight:'bold'}} variant="h5">Organization Information</Typography>
                    <VerticalSpacing value='10px'/>

                    { (infoChanged && !saving) && <Button variant="contained" onClick={() => saveNewInfo()} disable={saving.toString()}>Save</Button> }

                    <VerticalSpacing value='10px'/>
        
                    <InputLabel id="category-input">{"Organization Name"}</InputLabel>
                    <TextField
                        placeholder="Aa"
                        row_count="1"
                        fullWidth
                        inputProps={{maxLength:100}}
                        value={senderFullName}
                        wrapping='off'
                        onChange={(e) => setNewSenderName(e.target.value)}
                    />

        
        
                    <VerticalSpacing value='10px'/>
                    <InputLabel id="category-input">{"Street Address"}</InputLabel> 
                    <TextField
      
                        placeholder="Aa"
                        inputProps={{maxLength:100}}
                        row_count="1"
                        fullWidth
                        value={streetAddress}
                        wrapping='off'
                        onChange={(e) => setNewStreetAddress(e.target.value)}
                    />

                    <VerticalSpacing value='10px'/>
                    <InputLabel id="category-input">{"City"}</InputLabel>
                    <TextField
                        placeholder="Aa"
                        inputProps={{maxLength:100}}
                        row_count="1"
                        fullWidth
                        value={city}
                        wrapping='off'
                        onChange={(e) => setNewCity(e.target.value)}
                    />
                    
                    <VerticalSpacing value='10px'/>
                            

                    <InputLabel id="category-input">{"State"}</InputLabel>
                    <Select
                        label={'state'}
                        size="large"
                        value={state}
                        placeholder="Choose State"
                        fullWidth
                        onChange={handleStateSelected}>
                            
                        {all_states.map((type, index) => (
                            <MenuItem key={index} value={type}>{type}</MenuItem>
                        ))}
             
                    </Select>

                    <VerticalSpacing value='10px'/>

                    <InputLabel id="category-input">{"Zipcode"}</InputLabel>
                    <TextField
                        placeholder="Aa"
                        row_count="1"
                        inputProps={{maxLength:7}}
                        fullWidth
                        wrapping='off'
                        value={zipcode}
                        onChange={(e) => setNewZipcode(e.target.value)}
                    />
                    
                    <VerticalSpacing value='10px'/>


                    <Box  sx={{ padding:"5px" ,border: 1, borderRadius: '4px',  borderColor: 'grey.400' }}>

                        <InputLabel id="category-input">{"Contact Email"}</InputLabel>

                        <TextField
                            placeholder="Aa"
                            row_count="1"
                            fullWidth
                            inputProps={{maxLength:100}}
                            wrapping='off'
                            variant="filled"
                            value={email}
                            onChange={(e) => setNewEmail(e.target.value)}
                        />
                        
                        <VerticalSpacing value='20px'/>
                        
                        <Stack direction='row' alignItems='center' justifyContent='space-between' >

                            <Typography variant="subtitle2">Send Daily Email Summary</Typography>
                            <Switch
                                checked={emailSummary}
                                disabled={(email === undefined || email === null || email === "")}
                                onChange={setSendEmailSummary}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />

                        </Stack>
                    </Box >
                    
                    </Box>}  

                    <VerticalSpacing value='20px'/>

                        
                    <Typography sx={{fontWeight:'bold'}} variant="h6">{"Logged In User"}</Typography>

                    <Card padding='5px' variant="outlined">
                        <Stack direction='column'>
                            <Stack direction='row' sx={{padding:'5px', justifyContent:'space-between', alignItems:'center'}}>

                                    <Stack direction='column'>
                                    <Typography sx={{padding:'5px'}} variant="body1">{userData != null ? userData.email : ""}</Typography>

                                    <TextField
                                        placeholder="Full Name"
                                        row_count="1"
                                        fullWidth
                                        label='Full Name'
                                        inputProps={{maxLength:100}}
                                        wrapping='off'
                                        variant="filled"
                                        sx={{padding:'0px', marginBottom:"20px"}}
                                        value={fullName}
                                        onChange={(e) => setNewFullName(e.target.value)}
                                    />

                                    </Stack>
                                <Stack direction='column' spacing={2} justifyContent='space-between'>

                                    <Button variant="contained" id="logout" onClick={logoutUser}>Sign Out</Button>

                                    <Link  sx={{cursor:'pointer'}} onClick={() => navigate("/reset", {replace:false})} variant="body2">
                                        Change password
                                    </Link>

                                </Stack>
                            </Stack>


                        
                        </Stack>
                    </Card>  

                    <VerticalSpacing value={10}/>

                    { (infoChanged && !saving) && <Button variant="contained" onClick={() => saveNewInfo()} disable={saving.toString()}>Save</Button> }

                    <VerticalSpacing value={20}/>



                    <Stack direction='row' sx={{padding:'5px', justifyContent:'space-between'}}>
   
                        <Button variant="outlined" onClick={() => openFAQ()}>FAQs</Button>
                        <Button variant="outlined" onClick={() => openContactUs()}>Contact Us</Button>      
                    </Stack>

                 <VerticalSpacing value={20}/>
            </Container>
    
    
            <Modal
                open={showSupport}
                onClose={() => setShowSupport(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >

                <SupportPopupView 
                    handleRequest={(request) => handleSupportRequest(request)}
                    user={userData}
                />
            
            </Modal>


          <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
          {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
            {actionResults.success ? actionResults.success : actionResults.error}
          </Alert> : <div/>}

          </Snackbar>


      
        </Container>
    );
};

export default Account;