import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container"
import {useFirebase} from "../../FirebaseApp";

import icon from "../../Assets/logo_transparent_background.png"

import AsyncImage from "../Common/AsyncImage";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { InputAdornment, IconButton } from "@mui/material";
import { Copyright } from "../Common/Common";
import bgImage from "../../Assets/girl_piano_bw.webp"
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import splashImage from "../../Assets/send_free_card.webp"

function Register() {
    
  const { auth, user, analytics, registerWithEmailAndPassword} = useFirebase()
  const navigate = useNavigate();

  const noResults = {
    success: null,
    error:null
  };
  const [actionResults, setActionResults] = React.useState(noResults);
  const [showPassword, setShowPassord] = React.useState(false)

  function toggleShowPassword(){
    setShowPassord(!showPassword)
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      );
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let valid = validateEmail(data.get('email'))
    if(!valid){
      setActionResults({
        error:'Invalid Email',
        success: null
      });
      return
    }

    let result = await registerWithEmailAndPassword(data.get('name'), data.get('email'), data.get('password'));

    if(!result.success){
      let message = result.message
      const newString = message.split("Firebase:").pop();
      setActionResults({
        error:newString,
        success: null
      });
      return
    }else{
      setActionResults({
        error:null,
        success: 'Success!'
      });
      return
    }
  };




  useEffect(() => {

    if (user) navigate("/dashboard", {replace:true});

  }, [user, actionResults]);

  function handleSnackbarClose(){
    setActionResults(noResults);
  }

  function snackbarOpen(){
    return (Boolean(actionResults.success) || Boolean(actionResults.error))
  }

  return (

      <Grid container component="main" sx={{ height: '100vh' }}>

        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          >

          <Box sx={{
                backgroundColor: "#fff",
                color: "#000",
                opacity: ".6",
            }}>
              <Typography align="center" variant="h2"> You only get one first impression</Typography>
              <Typography align="right" variant="h5">Let it be remembered for a lifetime</Typography>
            </Box>
          </Grid>
        
        <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
          {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
            {actionResults.success ? actionResults.success : actionResults.error}
          </Alert> : <div/>}

        </Snackbar>
        
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square="true">
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >
            
            <AsyncImage height='auto' width='350px' draggable="false"  src={icon} alt="NOTE Logo"/>    

            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>

            <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Full Name"
                name="name"
                autoComplete="name"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
              <TextField

                margin="normal"
                name="password"
                  label="Password"
                  id="password"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowPassword}
                          onMouseDown={(e) => {
                            e.preventDefault();
                          }}
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              <TextField
                margin="normal"
                name="confirm-password"
                label="Confirm Password"
                id="confirm-password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                      >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />


              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2}}
              >
                Submit
              </Button>

              <Grid container spacing='10px' direction='column'>

                <Grid item>
                  <Link sx={{cursor:'pointer'}} onClick={() => navigate('/login', {replace:false})} variant="body2">
                    {"Already have an account? Sign In"}
                  </Link>
                </Grid>
                <AsyncImage padding='0px' height='auto' width='100%' draggable="false"  src={splashImage} alt="Free card"/>    

                <Grid item>
                  <Copyright/>
                </Grid>

              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>


  );



}

export default Register;
