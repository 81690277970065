import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Collapse, Container, InputAdornment, Box, Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Toolbar,Button,Typography,Paper,Checkbox,IconButton,Tooltip, TextField,} from '@mui/material';

import BackspaceIcon from '@mui/icons-material/Backspace';
import { visuallyHidden } from '@mui/utils';
import {VerticalSpacing} from '../Common/Common'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
  const {  order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'message',
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: 'recipient',
      numeric: false,
      disablePadding: false,
      label: "Recipient",
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: 'Address',
    },
    {
      id: 'date',
      numeric:true,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'type',
      numeric:false,
      disablePadding: false,
      label: 'Card Type',
    },
    {
      id: 'order',
      numeric: true,
      disablePadding: false,
      label: 'Order Id',
    }
  ];

  return (
    <TableHead>

      <TableRow>

        {headCells.map((headCell) => (

          <TableCell
            key={headCell.id}
            align='left'
            paddingleft='2px'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant='body' sx={{fontWeight:'bold'}}>{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          
        ))}
      </TableRow>
      
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function EnhancedTableToolbar(props) {
  const { title, handleSearch } = props;

  const [search, setSearch] = React.useState('');

  const editSearching = (event) => {
    let th = event.target.value
    setSearch(th)
    handleSearch(th)
  }

  const clearSearch = () => {
    setSearch('')
    handleSearch('')
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 1 },
        pr: { xs: 1, sm: 1 }
      }}
    >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="subtitle2"
          color="black"
          id="tableTitle"
          component="span">
          {title}
        </Typography>


        <TextField
          variant='outlined'
          placeholder="Search..."
          multiline={false}
          value={search}
          size='small'
          fullWidth
          onChange={(e) => editSearching(e)}
          InputProps={{
            endAdornment: 
              <InputAdornment position="end">
              <IconButton onClick={() => clearSearch()}><BackspaceIcon/></IconButton>

            </InputAdornment>,
          }}

          >
        </TextField>


    
    </Toolbar>
  );
}


const displayDate = (millis) =>{

  let date = new Date(millis).toLocaleDateString("en-US")
  return date
}

function Row(props) {
  const { row, key } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        hover
        backgroundColor={open ? 'primary' : 'secondary'}
        tabIndex={-1}
        key={key}
        size="medium"
        onClick={() => setOpen(!open)}
        >
        <TableCell
          padding="normal"
          scope="row"
        >
          <IconButton
            aria-label="expand row"
            size="medium"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell
          scope="row"
          id={key}
          padding="normal">
            {row.recipient}
        </TableCell>

        <TableCell
          id={key}
          scope="row"
          padding="normal">
            {row.address}
        </TableCell>

        <TableCell
          id={key}
          scope="row"
          padding="normal">
            {displayDate(row.date)}
        </TableCell>

        <TableCell
            id={key}
            scope="row"
            padding="normal">
             {(row.type !== "" && row.type != null) ? row.type : "scheduled"}
          </TableCell>

          <TableCell
            id={key}
            scope="row"
            padding="normal">
              {row.order}
          </TableCell>

        </TableRow>

        <TableRow
          backgroundColor={open ? 'primary' : 'secondary'}
          size="medium">

          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} scope="row" padding="normal" colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Container sx={{paddingTop:"10px", paddingBottom:"10px" }} maxWidth='sm'>
                <Typography style={{whiteSpace: 'pre-line'}} variant="body">
                  {(row.message != null && row.message !== "") ? row.message : '\n Message Display Unavailable \n'}
                </Typography>
                <VerticalSpacing value='30px'/>
              </Container>
            </Collapse>
          </TableCell>
        </TableRow>
    </React.Fragment>
  )
}

export function CardHistoryTable({cards, onSearch}) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [rows, setRows] = React.useState([])

  React.useEffect(() => {

    let allOrgs = organizeCards()
    setRows(allOrgs.rows)
  }, [cards]);

  function createData(id, recipient, address, date, type, order, message) {
    return {
      id,
      recipient,
      address,
      date,
      type,
      order,
      message,
    };
  }


  function organizeCards(){
    var rows = []
    var cardDict = {}

    if(cards === null || cards === undefined){
      return {rows:[], dict:{}}
    }
    for(const card of cards){

        cardDict[card.id] = card
        rows.push(createData(card.id, card.recipient, card.address, card.send_date, card.type, card.order_id, card.message))
    }

    return {rows:rows, dict:cardDict}
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%'}}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar title={rows.length === 1 ? rows.length + " result" : rows.length + " results"} handleSearch={(event) => onSearch(event)}/>
        <TableContainer>
          <Table
            sx={{ minWidth: 320 }}
            aria-labelledby="tableTitle"
            size='medium'
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>

              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  return(
                      <Row key={row.id} index={index} row={row} />
                  )
                })}
                  

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Box height={"100px"}/>

    </Box>
  );
}
