import { Container,  Button,  Grid, TextField } from '@mui/material';
import React from 'react';
import { VerticalSpacing } from '../Common/Common';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { InputAdornment, Modal, Stack,Paper, Box,IconButton, Accordion, AccordionSummary, AccordionDetails, Typography} from '@mui/material';
import MergeCodesPopup from '../Common/MergeCodesPopup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const TemplateCell = ({sendingOn, data, handleDelete, handleSave, handleShowMerge}) => {
    
    let id = data.id
    let maxChars = 500
    const [expanded, setExpanded] = React.useState(false)

    const [name, setName] = React.useState(data.name)
    const [subject, setSubject] = React.useState(data.subject)
    const [body, setBody] = React.useState(data.template)
    const [updated, setUpdated] = React.useState(false)
    const [remainingCharacters, setRemainingCharacters] = React.useState(data.template.length)



    const handleChange = () => () => {
        setExpanded(!expanded);
    };


    const handleBodyChange = (event) =>{
        let tbody = event.target.value

        if(tbody.length >= maxChars){
            return
        }

        if(tbody !== body){
            setUpdated(true)
        }
        setRemainingCharacters(maxChars - tbody.length)
        setBody(tbody)

    }
    const handleNameChange = (event) =>{
        if(event.target.value !== name){
            setUpdated(true)
        }
        setName(event.target.value)
    }

    const handleSaveEvent = () =>{
        setUpdated(false)
        setExpanded(false)
        handleSave(name, body, subject)
    }
    const handleDeleteEvent = () =>{
        setUpdated(false)
        setExpanded(false)

        handleDelete()
    }

    const handleShowMergeCodes = (value) =>{
        handleShowMerge(value)
    }
    return(

        <Paper variant='outlined' sx={{ marginTop:"10px"}}>

            <Stack justifyContent='space-between' fullWidth direction='column' key={id}>

                    <Accordion expanded={expanded} onChange={handleChange()}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={expanded && {
                                backgroundColor: "lightgray",
                                borderRadius: '0px'
                              }}
                            >

                            <Stack direction='row' spacing={{xs:0, md:5}}>
                                <Typography  variant='h6'  sx={{fontWeight:'bold'}}>{name}</Typography>
                                {(subject.trim().toLowerCase() === "note new client card") && <Button color={sendingOn ? 'primary' : "error"} variant='outlined'>{sendingOn ? 'Automated: On' : "Automated: Off"}</Button>}
                            </Stack>

                            
                        </AccordionSummary>

                        <AccordionDetails>
                            <Stack direction='row' justifyContent="flex-end" spacing={1}>
                                {updated && <Button variant='outlined' onClick={() => handleSaveEvent()}><Typography variant='body'>{"save"}</Typography></Button>}

                                <Button variant="contained"  fullWidth={false} onClick={() => handleShowMergeCodes(subject.trim().toLowerCase() === "note new client card")}>Merge Codes</Button> 

                                {(subject.trim().toLowerCase() !== "note new client card") && <IconButton onClick={() => handleDeleteEvent()}><DeleteOutlineIcon/></IconButton>}
                            </Stack> 
                            <VerticalSpacing value='10px'/>

                            {(subject.trim().toLowerCase() === "note new client card") && 
                                <Typography variant='body'>{"The automated new client card only supports limited merge codes seen by clicking the button above. You can change the name of this template but do not delete from Gazelle or your automated cards won't send."}</Typography> 
                            }
       

                            
                            {(subject.trim().toLowerCase() === "note new client card") && <VerticalSpacing value='20px'/>}
                            <Typography variant='body' sx={{fontWeight:'bold'}}>{"Name"}</Typography> 
                            <TextField
                                fullWidth
                                value={name}
                                onChange={(e) => handleNameChange(e)}/>

                            <VerticalSpacing value='10px'/>
                            <Typography  variant='body'  sx={{fontWeight:'bold'}}>{"Body"}</Typography>

                            <TextField
                                fullWidth
                                multiline
                                value={body}
                                rows={11}
                                onChange={(e) => handleBodyChange(e)}
                                InputProps={{
                                    endAdornment:(
                                        <InputAdornment sizeSmall variant='filled' position="end">
                                            <Typography color={remainingCharacters <= 0 ? "error" : "success"} variant='subtitle2'>
                                                {remainingCharacters + " chars"}
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }}
                            /> 
                            
                        </AccordionDetails>
                    </Accordion>

            </Stack>
        </Paper>
    
    ) 
}




const TemplateList = ({sendingOn, data, onDelete, onSave}) => {
    
    const final = [];
    const [showMergeCodesPopup, setShowMergeCodesPopup] = React.useState(false)
    const [isNew, setIsNew] = React.useState(false)

    const showMergeCodes = (isNewAuto) =>{
        setIsNew(isNewAuto)
        setShowMergeCodesPopup(true)

    }
    
    for(let temp of data){
        if(temp !== undefined ){
            final.push(
                <TemplateCell handleShowMerge={(isNewAuto) => showMergeCodes(isNewAuto)} sendingOn={sendingOn} data={temp} handleDelete={() => onDelete(temp)} handleSave={(name, body, subject) => onSave(temp, name, body, subject)}/>
            );
        }
    }
    
    return (
        <Box>
            {final}
            <VerticalSpacing value="5px"/>

            <Modal
                open={showMergeCodesPopup}
                onClose={() => setShowMergeCodesPopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >

                <MergeCodesPopup
                    isNewClient={isNew}
                    handleClose={() => setShowMergeCodesPopup(false)}
                />

         </Modal>
        </Box>
    )
}



export default TemplateList;