import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AsyncImage from "../Common/AsyncImage";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import icon from "../../Assets/logo_transparent_background.png"
import { useFirebase } from "../../FirebaseApp";

import { HorizontalSpacing, VerticalSpacing } from "../Common/Common";
import bgImage from "../../Assets/girl_piano_bw.webp"
import splashImage from "../../Assets/send_free_card.webp"
import { Link, Stack } from "@mui/material";


function Splash() {

    const {user} = useFirebase()
    const navigate = useNavigate();
  
    
    useEffect(() => {

        goHome()
    }, [user]);
    

    const gotoLogin = () => {

      if (user) {
        navigate("/home", {replace:false});
      }else{
        navigate("/login", {replace:false});
      }
    }

    const goHome = () =>{
      if (user) {
        navigate("/home", {replace:false});
      }
    }

    const gotoRegister = () =>{
      if (user) {
        navigate("/home", {replace:false});
      }else{
        navigate("/register", {replace:false});
      }
    }

    const gotoHowItWorks = () =>{
        navigate("/intro", {replace:false});
    }

   return(
      <Grid container  component="main" sx={{ height: '100vh' }}>

        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >

          <Box sx={{
              backgroundColor: "#fff",
              color: "#000",
              opacity: ".6",
          }}>
            <Typography align="center" variant="h2"> You only get one first impression</Typography>
            <Typography align="right" variant="h5">Let it be remembered for a lifetime</Typography>
          </Box>


        </Grid>
   
        <Grid item xs={12} sm={8} md={5}  elevation={6} square="true">
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >

              <AsyncImage height='auto' width='100%' draggable="false" src={icon} alt="NOTE Logo"/>    
              
              <VerticalSpacing value='45px'/>

              <Typography variant="body2"> Sign up to send a free card </Typography>
              <VerticalSpacing value='15px'/>
                
              <Button color="primary" sx={{width:'350px'}} variant="contained" size="large"  onClick={gotoLogin}> Continue </Button>  

              <VerticalSpacing value='15px'/>

              <Link sx={{cursor:"pointer"}} onClick={() => gotoHowItWorks()}>How it Works</Link>


          </Box>

          <Button height='auto' width='100%' sx={{cursor:"pointer"}}>
            <AsyncImage  height='auto' width='100%' src={splashImage} alt="Free card" onClick={gotoRegister}/>    

          </Button>

        </Grid>

      </Grid>


  );   

}

export default Splash;
