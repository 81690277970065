import React  from "react";
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';

const AsyncImage = (props) => {
    const [loadedSrc, setLoadedSrc] = React.useState(null);

    React.useEffect(() => {
        setLoadedSrc(null);

        
        if (props.src) {

            const handleLoad = () => {
                setLoadedSrc(props.src);
            };
            const image = new Image();
            image.addEventListener('load', handleLoad);
            image.src = props.src;

            return () => {
                image.removeEventListener('load', handleLoad);
            };
        }
    }, [props.src]);

    if (loadedSrc === props.src) {
        return (
            <img {...props} loading='lazy' draggable="false" />
        );
    }else if( props.src  === ''){
        return (
            <div>
            
            </div>
        )
    }

    return (
        <div className={props.className}>
          <Skeleton animation="pulse" variant="rectangular" width='100%'  height='100%'/>
        </div>
    );
}


export default AsyncImage;
