import { Container, Box } from "@mui/material";
import React from "react";

const Bars = () =>{
  return(
    <svg width="135" height="140" viewBox="0 0 135 140" xmlns="http://www.w3.org/2000/svg" fill="#009688">
    <rect y="10" width="15" height="120" rx="6">
        <animate attributeName="height"
             begin="1s" dur="2s"
             values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
             repeatCount="indefinite" />
        <animate attributeName="y"
             begin="1s" dur="2s"
             values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
             repeatCount="indefinite" />
    </rect>
    <rect x="30" y="10" width="15" height="120" rx="6">
        <animate attributeName="height"
             begin="0.5s" dur="2s"
             values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
             repeatCount="indefinite" />
        <animate attributeName="y"
             begin="0.5s" dur="2s"
             values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
             repeatCount="indefinite" />
    </rect>
    <rect x="60" width="15" height="140" rx="6">
        <animate attributeName="height"
             begin="0s" dur="2s"
             values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
             repeatCount="indefinite" />
        <animate attributeName="y"
             begin="0s" dur="2s"
             values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
             repeatCount="indefinite" />
    </rect>
    <rect x="90" y="10" width="15" height="120" rx="6">
        <animate attributeName="height"
             begin="0.5s" dur="2s"
             values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
             repeatCount="indefinite" />
        <animate attributeName="y"
             begin=".5s" dur="2s"
             values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
             repeatCount="indefinite" />
    </rect>
    <rect x="120" y="10" width="15" height="120" rx="6">
        <animate attributeName="height"
             begin="1s" dur="2s"
             values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
             repeatCount="indefinite" />
        <animate attributeName="y"
             begin="1s" dur="2s"
             values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
             repeatCount="indefinite" />
    </rect>
</svg>
  )
}


const BreathingDots = () =>{
    return(
  
      <svg width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#009688">
        <circle cx="15" cy="15" r="9" fill-opacity="0.3">
            <animate attributeName="r" from="9" to="9"
                    begin="0s" dur="3s"
                    values="9;15;9;9;9" calcMode="linear"
                    repeatCount="indefinite"
                    
                    />
            <animate attributeName="fill-opacity" from=".5" to=".5"
                    begin="0s" dur="3s"
                    values=".5;1;.5;.5;.5" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="60" cy="15" r="9" fill-opacity="0.3">
            <animate attributeName="r" from="9" to="9"
                    begin="0.5s" dur="3s"
                    values="9;15;9;9;9" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="0.5" to="0.5"
                    begin="0.5s" dur="3s"
                    values=".5;1;.5;.5;.5" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="105" cy="15" r="9"  fill-opacity="0.3">
            <animate attributeName="r" from="9" to="9"
                    begin="1.0s" dur="3s"
                    values="9;15;9;9" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from=".5" to=".5"
                    begin="1.0s" dur="3s"
                    values=".5;1;.5;.5;.5" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
    </svg>
    )
}


export default function Loading({text}) {

  return (

    <Box >
      <BreathingDots/>
    </Box>
  );
}