import React from 'react'
import {styled} from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { Paper, TextField } from "@mui/material";
import { VerticalSpacing } from "./Common";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '320px',
    bgcolor: 'background.paper',
    borderRadius:'5px',
    boxShadow: 24,
    p: 4
};
 

export default function SupportPopupView ({ user, handleRequest}) {

    const [message, setMessage] = React.useState("")
    const [email , setEmail] = React.useState("")

    const textChanged = (text) =>{
        setMessage(text)
    }
    
    const emailChanged = (text) =>{
        setEmail(text)
    }

    const handleSubmit = () =>{
        var request = { uid:user.uid, email:email, name: user.name, message:message}
        handleRequest(request)
    }
    

  
    return (
        
        <Container maxWidth='sm' disableGutters={true}  sx={style}>
                    
            <Box sx={{ width:'100%', padding:'5px'}}>

                    <Typography sx={{fontWeight:'bold'}} variant="h6">Contact Us</Typography>    
                    <TextField 
                    value={user != null ? user.email : ""}
                    onChange={(e) => emailChanged(e.target.value)}
                    inputProps={{maxLength:500}}
                    fullWidth
                    />   
                    <VerticalSpacing value='20px'/>

                    <TextField
                        row_count="10"
                        inputProps={{maxLength:500}}
                        fullWidth
                        height="200px"
                        multiline
                        placeholder="Enter Message (Max 500 characters)"
                        onChange={(e) => textChanged(e.target.value)}
                    />
                    <VerticalSpacing value='20px'/>
                    <Button variant="contained" onClick={() => handleSubmit()}>Submit</Button>
                    
            </Box>

         </Container>
    );
}
    