import React from "react"
import Loading from "./Loading"             
import { Box, Card, Button, Typography, Paper, Container, Snackbar, Alert, TextField, Stack } from "@mui/material";
import { VerticalSpacing } from "./Common";



const style = {
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, -10%)',
    minWidth: '320px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:5,
    overflowY: "auto",
    p: 4
};
 



export default function SendOneCardPopup ({handleUpdate, organization, userName, close}) {

    const [sending, setSending] = React.useState(false)
    const noResults = {
        success: null,
        error:null
    };
    
    const [actionResults, setActionResults] = React.useState(noResults);
    function handleSnackbarClose(){
        setActionResults(noResults);
    }

    const [address, setAddress] = React.useState(organization.streetAddress)
    const [city, setCity] = React.useState(organization.city)
    const [state, setState] = React.useState(organization.state)
    const [zipcode, setZipcode] = React.useState(organization.zipcode)
    
    function snackbarOpen(){
        return (Boolean(actionResults.success) || Boolean(actionResults.error))
    }
    const cardText = "Hello " + userName + ", \n\n Creating long-term relationships with clients is priority #1. A creative way to build these relationships is to send thank you cards. NOTE allows you to send cards, like this one, conveniently through Gazelle. Let clients know you appreciate hearing about their kids, dogs, joys, and pains. They will love you for it!\n\nP.S. NOTE can be automated! Auto-send an introduction card to a new client; they will love you before they meet you :).\n\nSincerely,\nJustin & Nathaniel"
    


    const prepareUpdates = () => {

        let cardData = {message:cardText, name: userName}


        if(address === undefined || address === null || address === ""){
            setActionResults({
                success:null,
                error:"Address is not valid, update from the Account page to send"
            })
            return
        }else{
            cardData.address = address
        }

        if(city === undefined || city === null || city === ""){
            setActionResults({
                success:null,
                error:"City is not valid, update from the Account page to send"
            })
            return
        }else{
            cardData.city = city
        }

        if(state === undefined || state === null || state === ""){
            setActionResults({
                success:null,
                error:"State is not valid, update from the Account page to send"
            })
            return
        }else{
            cardData.state = state
        }

        if(zipcode === undefined || zipcode === null || zipcode === ""){
            setActionResults({
                success:null,
                error:"Zipcode is not valid, update from the Account page to send"
            })

            return
        }else{
            cardData.zipcode = zipcode
        }

        setSending(true)
        handleUpdate(cardData)
    }


    
    const updateAddress = (value) =>{
        setAddress(value)
    }

    const updateState = (value) =>{
        setState(value)
    }

    const updateCity = (value) =>{
        setCity(value)
    }

    const updateZip = (value) =>{
        setZipcode(value)
    }

    return(

        <Container maxWidth='sm' disableGutters={true}  sx={style}>

            <Box justifyContent='right'>
                <Card elevation={0} sx={{padding:'2px'}}>
                      <Typography  variant="body2">{"To:"}</Typography>
                      <Typography sx={{fontWeight:'bold'}} variant="h5">{userName}</Typography>
                      <VerticalSpacing value="20px"/>

                      <TextField fullWidth label='Address' value={address} onChange={(e) => updateAddress(e.target.value)}/>
                      <VerticalSpacing value="10px"/>

                      <TextField fullWidth label='City' value={city}  onChange={(e) => updateCity(e.target.value)}/>

                      <VerticalSpacing value="10px"/>

                      <TextField fullWidth label='State' value={state} onChange={(e) => updateState(e.target.value)}/>
                      <VerticalSpacing value="10px"/>

                      <TextField fullWidth label='Zipcode' value={zipcode} onChange={(e) => updateZip(e.target.value)}/>
                      <VerticalSpacing value="20px"/>
                      <Typography style={{whiteSpace: 'pre-line'}} variant="body">{cardText}</Typography>
           
                    {sending &&   <Loading text="Sending Card"/>}
                    
                </Card>
                
                <VerticalSpacing value='10px'/>


                <Stack direction='row' spacing={2} justifyContent='flex-end'>
                    <Button variant="outlined" disabled={sending} onClick={() => close()}>Close</Button> 

                    <Button variant="contained" disabled={sending} onClick={() => prepareUpdates()}>Send</Button> 


                </Stack>


            </Box>              


            <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
            {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
            {actionResults.success ? actionResults.success : actionResults.error}
            </Alert> : <div/>}

            </Snackbar>

        </Container>
        
        )

}


