


function Organization(data){


    let org = {
        name:"",
        companyId:"",
        members:[],
        cardFont: 'hwJonathan',
        cardTemplateId: '115',
        subscriptionExpireTime: new Date(),
        sendEmailSummary:false,
        email:"",
        usedFreeCard : false,
        subscriptionItemId:"",
        subscriptionType:"",
        gazelleLinked:false,
        lastBilledDate: new Date(),
        stripeClientId:"",
        senderFullName:"",
        streetAddress:"",
        state:"",
        cardsSent:0,
        city:"",
        zipcode:"",
        appt:"",
        name:""

    }
    
    
    if(data !== undefined){
        


        if(data.cardFont !== undefined){
            org.cardFont = data.cardFont
        }

        if(data.cardTemplateId !== undefined){
            org.cardTemplateId = data.cardTemplateId
        }
    
    
        if(data.streetAddress !== undefined){
            org.streetAddress = data.streetAddress
            
        }
    
        if(data.appt !== undefined){
            org.appt = data.appt
            
        }

    
        if(data.city !== undefined){
            org.city = data.city
            
        }

    
        if(data.state !== undefined){
            org.state = data.state 
        } 
        if(data.zipcode !== undefined){
            org.zipcode = data.zipcode
        }  
    
        if(data.id !== undefined){
            org.id = data.id
            
        }  
    
        if(data.name !== undefined){
            org.name = data.name
            
        }  
        if(data.cardsSent !== undefined){
            org.cardsSent = data.cardsSent
            
        }  
        
        
        
        if(data.usedFreeCard !== undefined){

            org.usedFreeCard = data.usedFreeCard
        }
        
        if(data.email !== undefined){

            org.email = data.email
        }
        
        
        if(data.companyId !== undefined){

            org.companyId = data.companyId
        }
        
        if(data.subscriptionExpireTime !== undefined){

            org.subscriptionExpireTime = data.subscriptionExpireTime
        }
        
        if(data.subscriptionItemId !== undefined){

            org.subscriptionItemId = data.subscriptionItemId
        }
        
        if(data.cardsSent !== undefined){

            org.cardsSent = data.cardsSent
        }
        
        
                
        if(data.stripeClientId !== undefined){

            org.stripeClientId = data.stripeClientId
        }
        
    
    }
    
    return org;
};

export default Organization;

