import { red } from "@mui/material/colors";

export const colors = {
  green:{
    main:"#00695f",
    light:"#009688",
    dark:"#33ab9f"
  },

  white:{
    main:"#ffffff",
    light:"#ffffff",
    dark:"#ffffff"
  },
  black:{
    main:"#000000",
    light:"#424242",
    dark:"#000000"
  },
  gray:{
    main:"#9e9e9e",
    light:"#f5f5f5",
    dark:"#424242"
  },
  contrastText:{
    light:"#FFF",
    dark:"#000"
  },

  red,
};
