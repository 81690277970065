import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Collapse, InputAdornment, Box, Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Toolbar,Button,Typography,Paper,Checkbox,IconButton,Tooltip, TextField,} from '@mui/material';

import BackspaceIcon from '@mui/icons-material/Backspace';
import { visuallyHidden } from '@mui/utils';
import {VerticalSpacing} from '../Common/Common'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from 'react-router-dom';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}




function EnhancedTableHead(props) {
  const {  order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'date',
      numeric:true,
      disablePadding: false,
      label: 'Date',
    },

    {
      id: 'cards',
      numeric: false,
      disablePadding: false,
      label: "Cards Sent",
    },
    {
      id: 'cost',
      numeric: false,
      disablePadding: true,
      label: 'Total Cost',
    },


    {
      id: 'invoice',
      numeric: true,
      disablePadding: false,
      label: 'Invoice',
    }
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            paddingleft='2px'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant='body' sx={{fontWeight:'bold'}}>{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function EnhancedTableToolbar(props) {
  const { title } = props;


  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="subtitle2"
          color="black"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>


    </Toolbar>
  );
}


const displayDate = (millis) =>{

  let date = new Date(millis).toLocaleDateString("en-US")
  return date
}



export function BillingHistoryTable({bills, handleUrl}) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [rows, setRows] = React.useState([])

  React.useEffect(() => {

    let allBills = organizeBills()
    setRows(allBills.rows)
  }, [bills]);

  function createData(id,date, cards, cost, invoice) {
    return {
      id,
      date,
      cards,
      cost,
      invoice
    };
  }


  function organizeBills(){
    var rows = []
    var billDict = {}

    if(bills === null || bills === undefined){
      return {rows:[], dict:{}}
    }
    for(const bill of bills){

      billDict[bill.id] = bill
        rows.push(createData(bill.id, bill.timestamp, bill.amount, bill.total_cost,  bill.invoice_url))
    }

    return {rows:rows, dict:billDict}
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%'}}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar title={rows.length === 1 ? rows.length + " result" : rows.length + " results"}/>
        <TableContainer>
          <Table
            sx={{ minWidth: 320 }}
            aria-labelledby="tableTitle"
            size='medium'
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>

              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  let key = row.id
                return(
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={key}
                    size="medium"
                    >

                    <TableCell
                      component="th"
                      id={key}
                      scope="row"
                      padding="normal">
                        {displayDate(row.date)}
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      id={key}
                      padding="normal">
                        {row.cards}
                    </TableCell>
            
                    <TableCell
                      component="th"
                      id={key}
                      scope="row"
                      padding="normal">
                        {"$"+ (row.cost / 100).toFixed(2)}
                    </TableCell>
            

            
                    <TableCell
                        component="th"
                        id={key}
                        scope="row"
                        padding="normal">
                         <Link onClick={() => handleUrl(row.invoice)}>View Invoice</Link>
                    </TableCell>

                    </TableRow>
                  )
                })}
                  

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Box height={"100px"}/>

    </Box>
  );
}
