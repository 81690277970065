import React from "react"
import Loading from "./Loading"             
import { Card, Button, Typography, Paper, Container, Stack, Box, Snackbar, Alert } from "@mui/material";
import { VerticalSpacing } from "./Common";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxHeight:'100%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    overflowY:'auto',
    boxShadow: 24,
    p: 2
};
 



export default function MergeCodesPopup ({isNewClient, handleClose}) {

    const copyMergeCode = (text) => {
        navigator.clipboard.writeText(text)

        setActionResults({
            error:null,
            success: "Copied " + text
        });
    }

    const noResults = {
        success: null,
        error:null
    };
    
    const [actionResults, setActionResults] = React.useState(noResults);
    function handleSnackbarClose(){
        setActionResults(noResults);
    }
    
    function snackbarOpen(){
        return (Boolean(actionResults.success) || Boolean(actionResults.error))
    }
    return(

        <Container maxWidth='sm' disableGutters={true}  sx={style}>

                <Stack direction="row" justifyContent='space-between' alignItems='center'>

                    <Typography variant="body"  sx={{ fontWeight:'bold'}}>{"Available Merge Codes"}</Typography>

                    <Button variant="contained" onClick={() => handleClose()}>Close</Button> 
                </Stack>
                <VerticalSpacing value="10px"/>
                <Typography variant="subtitle2">{!isNewClient ? "See full list of merge codes in Gazelle -> Settings -> Custom Messages" : "The new client card template only supports these codes."}</Typography>

                <VerticalSpacing value="10px"/>


                <Typography variant="h6" sx={{ fontWeight:'bold'}}>{"Client"}</Typography>


                <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{client.first_name}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{client.first_name}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">For your client, the default contact's (if present) first name</Typography>
                    </Stack>
                </Card>
                
                <VerticalSpacing value="10px"/>

                <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{client.last_name}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{client.last_name}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">For your client, the default contact's (if present) last name</Typography>
                    </Stack>
                </Card>

                {!isNewClient && <VerticalSpacing value="10px"/>}

                {!isNewClient &&  <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{client.company_name}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{client.company_name}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">Your client's company name (if present)</Typography>
                    </Stack>
                </Card>}

                {!isNewClient && <VerticalSpacing value="10px"/>}

                {!isNewClient &&  <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{client.formal_name}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{client.formal_name}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">If a company name is present, this will be the company name. Otherwise this will be the client's default contact (if present) first and last name.</Typography>
                    </Stack>
                </Card>}

                {!isNewClient && <VerticalSpacing value="10px"/>}

                {!isNewClient &&  <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{client.address}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{client.address}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">For your client, the default contact's (if present) default address (if present) in a single line format</Typography>
                    </Stack>
                </Card>}

                {!isNewClient && <VerticalSpacing value="10px"/>}

                {!isNewClient &&  <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{client.primary_phone}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{client.primary_phone}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">For your client, the default contact's (if present) default phone number (if present)</Typography>
                    </Stack>
                </Card>}

                {!isNewClient && <VerticalSpacing value="10px"/>}

                {!isNewClient &&  <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{client.primary_email}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{client.primary_email}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">For your client, the default contact's (if present) default email address (if present)</Typography>
                    </Stack>
                </Card>}



                {!isNewClient &&  <Typography variant="h6" sx={{ fontWeight:'bold'}}>{"Pianos"}</Typography>}

                {!isNewClient &&  <VerticalSpacing value="10px"/>}

                {!isNewClient &&   <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{all_pianos}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{all_pianos}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">A list of relevant pianos can be added to the email by using the 'All Pianos' merge code. Which pianos are in that list is dependent on the context.</Typography>
                    </Stack>
                </Card>}





                <Typography variant="h6" sx={{ fontWeight:'bold'}}>{"Company"}</Typography>

                <VerticalSpacing value="10px"/>

                <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{company.name}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{company.name}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">Your company name</Typography>
                    </Stack>
                </Card>


                <VerticalSpacing value="10px"/>

                <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{company.phone}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{company.phone}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">Your company phone number</Typography>
                    </Stack>
                </Card>


                {!isNewClient && <VerticalSpacing value="10px"/>}

                {!isNewClient &&  <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{company.email}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{company.email}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">Your company email address</Typography>
                    </Stack>
                </Card>}
                {!isNewClient && <VerticalSpacing value="10px"/>}

                {!isNewClient &&  <Card variant="outlined" elevation={0} sx={{padding:'2px'}}>
                    <Stack direction='column'>

                        <Stack direction="row" justifyContent='space-between' alignItems='center'>
                            <Typography variant="subtitle2" sx={{ fontWeight:'bold'}}>{"{{company.website}}"}</Typography>
                            <Button size="small" variant="outlined" onClick={() => copyMergeCode("{{company.website}}")}>Copy</Button>
                        </Stack>

                        <Typography variant="body">Your company website</Typography>
                    </Stack>
                </Card>}

            

     

            <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
            {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
            {actionResults.success ? actionResults.success : actionResults.error}
            </Alert> : <div/>}

            </Snackbar>
        </Container>
        
        )

}


