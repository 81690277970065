import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router";
import { VerticalSpacing } from "../Common/Common";
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import bgImage from "../../Assets/girl_piano_bw.webp"

import { useFirebase } from "../../FirebaseApp";
import { useNote } from "../../hooks/useNote";
import { TopNavigationBar } from "../Common/TopNavigationBar";
import ConfirmationPopup from "../Common/ConfirmationPopup"
import SendOneCardPopup from "../Common/SendOneCardPopup"
import { Box, Snackbar, Alert, Card, Modal, Paper, Stack, Typography, Switch, Stepper, Step, StepLabel } from "@mui/material";

import Introduction from "../Introduction/Introduction";


function Dashboard() {

  const { user } = useFirebase()
  const { userData, organization, loading, checkSubscribed, sendFreeCardOnce, updateOrganization} = useNote()
  
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showSendFreeCardPopup, setShowSendFreeCardPopup] = useState(false)

  const [confirmationTitle, setConfirmationTitle] = useState("Success")
  const [confirmationBody, setConfirmationBody] = useState("Technician Updated")


  const [showLinkGazellPopup, setShowLinkGazellePopup] = useState(false)
  const linkGazellePopupTitle = "Linking Gazelle and your Data"
  const linkGazellePopupBody = "You have full control over the cards you send and the Gazelle data NOTES has access to. \n\n NOTE uses its access to: \n\n - Read and update client timeline entries \n - Read and update phone templates \n - Read new client information (only if automatic cards to new clients is enabled.) \n\n You can revoke NOTE\'s access to your gazelle account from gazelle at any time. \n\nYou will be redirected to a gazelle page to login."

  const steps = ['Link Gazelle Account',  "Edit/Create Custom Templates", 'Send A Free Card', 'Finalize'];
  const alternateSteps = ['Provide your gazelle credentials to securely link your gazelle account to NOTE. You have full control over the cards you send as well as the data NOTES has access to.', ' Create custom templates for use in Gazelle. We have added two default NOTE templates when you linked your account. View and edit them from our templates page.', "See what a NOTE  is all about and send yourself an example card.", 'Send cards directly from Gazelle, make a great first impression, and create lifetime clients. '];

  const [activeStep, setActiveStep] = React.useState(0)

  const noResults = {
    success: null,
    error:null
  };
  const [actionResults, setActionResults] = React.useState(noResults);

  function handleSnackbarClose(){
    setActionResults(noResults);
  }


  function snackbarOpen(){
    return (Boolean(actionResults.success) || Boolean(actionResults.error))
  }


  const CLIENT_ID = "uECaJaEq-pInIpm37G4WyLU-oLk4U7osjVhgKlwK-NU"
  const REDIRECT_URI = "https://pianonotes.app/api/callback"

  const navigate = useNavigate();
  
  useEffect(() => {

    if(loading)return;
    if (!user) { navigate("/welcome", {replace:true}); }

    checkActiveStep()

  }, [user, userData, organization, loading]);     



  const checkActiveStep = () => {

      if(organization === null || organization === undefined){
          setActiveStep(0)
          return
      }else if(organization.viewedTemplates !== true){
          setActiveStep(1)
          return
      }
      else if(organization.usedFreeCard !== true){

          setActiveStep(2)
          return
      }else if(!checkSubscribed()){
          setActiveStep(3)
          return
      }else {
          setActiveStep(4)
          return
      }
  };

  //navigate to oauth endpoint
  const linkGazelle = async () => {
    
    let encodedUid = encodeURIComponent(user.uid)
    let encodeddEmail = encodeURIComponent(user.email)
    let encodedName = encodeURIComponent(userData.name)
    let stateData = JSON.stringify({user_id:encodedUid, email:encodeddEmail, name:encodedName})

    const authUrl = `https://gazelleapp.io/developer/oauth/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&state=${stateData}`;           
    openInNewTab(authUrl)

  }



  
  const takeMeAction = () =>{
    setShowSendFreeCardPopup(true)
  }
  
  
  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }
  
  
  const handleSendFreeCard = async(cardData) => {

    let updates = {}
    updates.companyId = organization.companyId
    updates.message = cardData.message
    updates.address = cardData.address
    updates.state = cardData.state
    updates.city = cardData.city
    updates.zipcode = cardData.zipcode
    updates.name = cardData.name
      
    let res = await sendFreeCardOnce(updates)
    
    if(res === true){
        setConfirmationTitle("Congrats!")  
        setConfirmationBody("Your sample card is in the mail. Just imagine what your clients will feel when they receive a high quality handwritten card from your business in their mailbox. Their jaw will hit the floor because nobody does this anymore. We hope you enjoy this experience and we look forward to helping you create lasting first impressions that create lifetime clients for your business! ")  
    }else{
        setConfirmationTitle("Oops")  
        setConfirmationBody("Something went wrong")  
    }
    setShowSendFreeCardPopup(false)
    setShowConfirmation(true)
      
  }


  const toAccount = () =>{
    navigate("/account", {replace:false});
  }

  const toBilling = () =>{
    navigate("/subscription", {replace:false});
  }

  const toHistory = () =>{
    navigate("/cards", {replace:false});
  }
  const toHowTo = () =>{
    navigate("/intro", {replace:false});
  }

  const editTemplates = () => {
    navigate("/templates", {replace:false});
  }




  return (
    // <Grid container component="main" sx={{ height: '100%' }}>

    //     <CssBaseline />

    //     <Grid item xs={12} sm={8} md={5}  elevation={6} square="true">

        <Container className="main-container"  maxWidth={false} disableGutters sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: "fixed",
            maxHeight:'100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
            { user && <TopNavigationBar/>}                                                   
            <Container  maxWidth='sm' disableGutters style={{ background: '#ffffff', paddingLeft:'10px', paddingRight:'10px', paddingTop:'10px', borderRadius: 2 }}>


                  {organization && 
                        <Card sx={{padding:'5px'}}>

                          <Typography  variant="caption">{"Organization"}</Typography>
                          <Typography sx={{fontWeight:'bold'}} variant="h5">{organization.name}</Typography>

                          <VerticalSpacing value={10}/>
                          <Typography variant="body2">{organization.streetAddress}</Typography>
                          <Typography variant="body2">{organization.city + ", " + organization.state}</Typography>
                          <Typography variant="body2">{organization.zipcode}</Typography>

                        </Card>
                  }

                  <VerticalSpacing value='15px'/>

                  <Card sx={{padding:'5px'}}>
                    <Stack direction='column' sx={{backgroundColor: "white"}}>
                  
                      <Typography sx={{fontWeight:'bold'}} variant="h6">{activeStep !== steps.length ?  "Setup Progress" : "Setup Complete"}</Typography>

                      {activeStep !== steps.length && <Box sx={{ width: '100%' }} m={1}>

                        <Stepper activeStep={activeStep} orientation='vertical'>
                          {steps.map((label, index) => {

                              return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                              );
                          })}
                        </Stepper>


                      </Box>}


                      {
                        activeStep === steps.length ? 
                            
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            {'You\'re all set to send cards!'}
                          </Typography>
                          : 
                          <Typography variant="body" sx={{ mt: 2, mb: 1 }}> 
                            {alternateSteps[activeStep]}

                          </Typography>
                      }


                      <VerticalSpacing value={1}/>
                      {activeStep === 0 && <Stack direction={{xs:'column', md:'row'}} justifyContent='end'>

                          <Button
                              size="small"
                              variant='contained'
                              onClick={() => setShowLinkGazellePopup(true)}
                            >
                            <Typography>Link Account</Typography>
                            
                          </Button> 
                      </Stack>}


                      {(activeStep === 1) && <Stack direction={{xs:'column', md:'row'}} justifyContent='end'>
                          <Button
                              size="small"
                              variant='contained'
                              onClick={() => editTemplates()}
                            >
                            <Typography>View Templates</Typography>
                            
                          </Button> 
                      </Stack>}


                      {(activeStep === 2) &&
                        
                            <Stack sx={{padding:'5px'}} direction={{sm:'column', md:'row'}} justifyContent='end'>

                              <Button
                                size="small"
                                variant='contained'
                                onClick={() => takeMeAction()}
                              >
                              <Typography> Send a Free Card</Typography>

                              </Button> 

                            </Stack>
                        }


                        {(activeStep === 3) && 

                              <Stack>

                                  <Typography  sx={{fontWeight:'bold'}}  variant="h6">{"Subscription"}</Typography>
                                  <Typography   variant="body">{"To send cards from Gazelle you must have a subscription. We bill monthly per card sent $4.38 (includes postage). If you don't send any cards, you won't get charged!"}</Typography>
                                  <Button  variant="contained" onClick={() => toBilling()}>{"To Subscription"}</Button>
                              </Stack> 
                        }

                    </Stack>
                  </Card>
                  

                  <VerticalSpacing value='20px'/>

                  {(organization) && 
                      <Stack spacing={5}>

                        {(checkSubscribed() && !loading) && <Stack direction='column' spacing={2}>

                          <VerticalSpacing value="10px"/>

                          <Typography  variant="h6">{"Where do you want to go?"}</Typography>
                          <Button fullWidth variant='contained' onClick={() => toHowTo()}>
                            <Typography >How does NOTE work?</Typography>
                          </Button> 
                          <Button  variant="outlined" onClick={() => toAccount()}>{"Account"}</Button>
                          <Button  variant="outlined"  onClick={() => toBilling()}>{"Subscription"}</Button>
                          <Button  variant="outlined"  onClick={() => editTemplates()}>{"Card Templates"}</Button>
                          <Button  variant="outlined" onClick={() => toHistory()}>{"Card History"}</Button>
                          
                        </Stack>}
                        <VerticalSpacing value="20px"/>

                    </Stack>
                  }


                {(activeStep !== steps.length) && <Introduction/> }
                
            </Container> 
                
          
            <Modal
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
              <ConfirmationPopup 
                  show={showConfirmation}
                  positive={"Close"}
                  title={confirmationTitle}
                  body={confirmationBody}
                  handleClose={() => setShowConfirmation(false)}
                  handlePositive={() => setShowConfirmation(false)}
                />
            </Modal>


            <Modal
                open={showLinkGazellPopup}
                onClose={() => setShowLinkGazellePopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
              <ConfirmationPopup 
                  show={showLinkGazellPopup}
                  positive={"Link"}
                  title={linkGazellePopupTitle}
                  body={linkGazellePopupBody}
                  handleClose={() => setShowLinkGazellePopup(false)}
                  handlePositive={() => linkGazelle()}
                />
            </Modal>

            

            {userData && <Modal
                sx={{overflowY:'auto'}}
                open={showSendFreeCardPopup}
                onClose={() => setShowSendFreeCardPopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                  <SendOneCardPopup 
                    close={() => setShowSendFreeCardPopup(false)}
                    handleUpdate={(updates) => handleSendFreeCard(updates)}
                    userName={userData.name}
                    organization={organization}
                  />

            </Modal>}


            <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
              {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
                {actionResults.success ? actionResults.success : actionResults.error}
              </Alert> : <div/>}

            </Snackbar>

                        
        </Container>


  );
}

export default Dashboard;
