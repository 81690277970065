
import React, { ReactComponent, useEffect, useState, userRef } from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import  List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import { ListButton, VerticalSpacing } from "./Common";
import ListIcon from '@mui/icons-material/List';
import StartIcon from '@mui/icons-material/Start';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HistoryIcon from '@mui/icons-material/History';
import ReceiptIcon from '@mui/icons-material/Receipt';

import CloseIcon from '@mui/icons-material/Close';
import { Navigate, Link, useNavigate } from "react-router-dom";
import {HorizontalSpacing, LeftBarSpacer, RightBarSpacer} from "./Common"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import Logo from "../../Assets/logo_transparent_background.png"
import { useFirebase } from "../../FirebaseApp";
import { Button, Typography } from "@mui/material";
import AsyncImage from "./AsyncImage";


import PaidIcon from '@mui/icons-material/Paid';


export function TopNavigationBar(){

  const { user } = useFirebase();
  const navigate = useNavigate()
  const [showBackButton, setShowBackButton] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  React.useEffect(() =>{

      if(!user){
        toSplash()
      }

      if(window.location.pathname === "/home"){
        setShowBackButton(false)
      }else{
        setShowBackButton(true)
      }

  }, [user, window.location.pathname])

  
  const toSplash = () =>{
    setIsDrawerOpen(false)
    navigate("/", {replace:true});
  }


  function backAction(){
    setIsDrawerOpen(false)
    navigate(-1)
  }


  function toBilling(){
    setIsDrawerOpen(false)
    navigate("/subscription", {replace:false});
  }

  function toCards(){
    setIsDrawerOpen(false)
    navigate("/cards", {replace:false});

  }

  function toAccount(){
    setIsDrawerOpen(false)
    navigate("/account", {replace:false});

  }
  
  function toIntroduction(){
    setIsDrawerOpen(false)
    navigate("/intro", {replace:false});

  }
  function toFAQ(){
    setIsDrawerOpen(false)
    navigate("/faq", {replace:false});

  }

  function toTemplates(){
    setIsDrawerOpen(false)
    navigate("/templates", {replace:false});

  }

  function toDashboard(){
    setIsDrawerOpen(false)
    navigate("/home", {replace:false});
  }

  function toContact(){
    setIsDrawerOpen(false)
    navigate("/contact", {replace:false});
  }

  const StyledDrawer = styled(Drawer)(({ theme }) => ({
    position: 'fixed',
    padding: '5px',
    zIndex: theme.zIndex.appBar - 1
  }));



  return(

    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={1} color="secondary" position="fixed">
        <Toolbar sx={{justifyContent:"space-between", alignItems:'center'}}>

            <Box   sx={{cursor:'pointer', height:'80px' }} onClick={() => toDashboard()} >
              <AsyncImage padding={0} height='80px' src={Logo}></AsyncImage>

            </Box>


            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="open drawer"
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            >
            {isDrawerOpen ?
            <CloseIcon/>
            : 
            <MenuIcon />
            }
            
            
          </IconButton>

          <StyledDrawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>

              <List sx={{paddingTop:'100px', minWidth:'325px'}}>

                <Box sx={{cursor:'pointer', width:'325px' }} onClick={() => toDashboard()} >
                  <AsyncImage onClick={() => toDashboard()} padding={0} width="325px" src={Logo}></AsyncImage>
                </Box>
                <ListButton
                  title={'Home'}
                  onClick={() => toDashboard()}
                  icon={<DashboardIcon/>}
                />

                <ListButton
                  title={"Card History"}
                  onClick={() => toCards()}
                  icon={<HistoryIcon/>}
                />

                <ListButton
                  title={"Subscription"}
                  onClick={() => toBilling()}
                  icon={<PaidIcon/>}
                />
                <ListButton
                  title={'Card Templates'}
                  onClick={() => toTemplates()}
                  icon={<ListIcon/>}
                />

                <ListButton
                  title={"Account"}
                  onClick={() => toAccount()}
                  icon={<ManageAccountsIcon/>}
                />

                <ListButton
                  title={"Contact Us"}
                  onClick={() => toContact()}
                  icon={<ContactSupportIcon/>}
                />

              <ListButton
                  title={"FAQs"}
                  onClick={() => toFAQ()}
                  icon={<LiveHelpIcon/>}
                />

                <VerticalSpacing value={10}/>
                <Button
                  fullWidth
                  variant='contained'
                  onClick={() => toIntroduction()}>How it Works</Button>

            </List>
          </StyledDrawer>
        </Toolbar>
      </AppBar>
    </Box>

  )
  
}
