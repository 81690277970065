import React, { useEffect, useState } from "react";


import { useNavigate } from "react-router";
import { useFirebase } from "../../FirebaseApp"; 
import  TemplateList from "./TemplateList"
import Loading from "../Common/Loading"
import { InputAdornment, Modal, TextField, Snackbar, Alert, Card, Container, Typography, Stack, Box, Button, IconButton, Accordion, AccordionSummary, AccordionDetails, Switch } from "@mui/material";
import { useNote } from "../../hooks/useNote";
import ClearIcon from '@mui/icons-material/Clear';
import { VerticalSpacing } from "../Common/Common";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ConfirmationPopup from "../Common/ConfirmationPopup";
import MergeCodesPopup from "../Common/MergeCodesPopup";
import { TopNavigationBar } from "../Common/TopNavigationBar";
import bgImage from "../../Assets/girl_piano_bw.webp"


function Templates() {
    const noResults = {
        success: null,
        error:null
    };
    
    const [actionResults, setActionResults] = React.useState(noResults);
    const {user} = useFirebase();
    const {userData, organization, loading, getTemplates, createTemplate, updateTemplate, deleteTemplate, updateOrganization} = useNote();
    const navigate = useNavigate();
    
    const [templates, setTemplates] = useState(undefined)
    const [expanded, setExpanded] = useState(false)
    const [updated, setUpdated] = useState(false)

    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [showMergeCodesPopup, setShowMergeCodesPopup] = useState(false)
    const [sendToNew, setSendToNew] = useState(false)

    const [newBody, setNewBody] = useState("")
    const [newName, setNewName] = useState("")
    const [remainingCharacters, setRemainingCharacters] = useState(0)

    const [deletingTemplate, setDeletingTemplate] = React.useState(undefined)

    
    function handleSnackbarClose(){
        setActionResults(noResults);
    }
    
    function snackbarOpen(){
        return (Boolean(actionResults.success) || Boolean(actionResults.error))
    }
    

    useEffect(() => {
        if(loading)return;
        if (!user) return navigate("/welcome", {replace:true});
        
        if(templates == null && !loading && userData != null && userData.companyId !== "") {
            loadTemplates()
        }



    }, [user, loading]);


    
    async function loadTemplates(){


        if(organization && !organization.viewedTemplates){
            await updateOrganization({viewedTemplates:true, companyId:organization.companyId})
            
        }else if(organization !== undefined && organization !== null){
            setSendToNew(organization.sendToNew)
        }

        const all_templates = await getTemplates(userData)

        setTemplates(all_templates)
    }


    const askDeleteConfirmation = (template) => {
        setDeletingTemplate(template)
        setShowDeletePopup(true)
    }

    const postiveDeleteAction = () =>{
        setShowDeletePopup(false)
        deleteAction()
    }

    const deleteAction = async() => {

        let id = deletingTemplate.id
        let data = {
            companyId:organization.companyId,
            templateId:id
        }
        deleteLocalTemplateData(deletingTemplate)

        let deleteRes = await deleteTemplate(data)

        if(deleteRes.success){
            setActionResults({
                error:null,
                success: "Template Deleted"
              });
        }else{
            setActionResults({
                error:"Failed to Update",
                success: null
              });
        }
    }

    const handleCreateSelected = () =>{

        setExpanded(!expanded)

    }

    const clearTemplate = () => {
        setNewBody("")
        setNewName("")
    }
    

    const saveAction = async(template, newName, newBody, newSubject) => {
        let id = template.id
        let data = {
            body:newBody,
            name:newName,
            subject:newSubject,
            companyId:organization.companyId,
            templateId:id
        }

        let saveRes = await updateTemplate(data)
        if(saveRes.success){
            updateLocalTemplateData(data)
            setActionResults({
                error:null,
                success: "Template Updated"
              });
        }else{
            setActionResults({
                error:"Failed to Update",
                success: null
              });
        }
        
    }

    const createAction = async() => {
        setUpdated(false)
        setExpanded(false)

        let newTemp = {
            companyId:organization.companyId,
            name:newName,
            body:newBody
        }
        let createdRes = await createTemplate(newTemp)
        if(createdRes.success){
            clearTemplate()
            setExpanded(false)
            await loadTemplates()
            setActionResults({
                error:null,
                success: "Template Created"
              });
        }else{
            setActionResults({
                error:"Failed to Update",
                success: null
              });
        }

    }

    function updateLocalTemplateData(data){
        let newList = []

        for(const temp of templates){   
            if(data.id !== temp.id){
                newList.push(temp)
            }else{
                temp.name = data.name
                temp.template = data.body

                newList.push(temp)
            }
        }

        setTemplates(newList)
    }

    function deleteLocalTemplateData(data){
        let newList = []

        for(const temp of templates){   
            if(data.id !== temp.id){
                newList.push(temp)
            }
        }

        setTemplates(newList)
    }

    const handleNameChange = (event) => {
        if(newName !== event.target.value){
            setUpdated(true)
        }
        setNewName(event.target.value)

    }
    const handleBodyChange = (event) => {

        let tbody = event.target.value
        if(newBody !== event.target.value){
            setUpdated(true)
        }

        setRemainingCharacters(tbody.length)
        setNewBody(event.target.value)
    }

    const changeSendToNew = async() => {

        let newSend = !sendToNew
        let updates = {sendToNew: newSend, companyId:organization.companyId}
    
        setSendToNew(newSend)
        const res = await updateOrganization(updates)
    
        if(newSend){
          setActionResults({
            success:"Enabled automatic new client cards",
            error:null
          })
        }else{
          setActionResults({
            success:null,
            error:"Disabled automatic new client cards"
          })
        }
      }
    
    return (
        <Container className="main-container" maxWidth={false}  sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: "fixed",
            maxHeight:'100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>

            { user && <TopNavigationBar/>} 

            <Container maxWidth='md' disableGutters style={{ background: '#ffffff', padding:'10px', borderRadius: 2 }}>


            {(organization) && 
                <Stack direction='column' spacing={2}>

                    <Card elevation={0} variant="outlined" sx={{padding:'5px'}}>

                        <Stack direction='row' alignItems='center' justifyContent='space-between' >
                            <Typography variant="h5">Automatically send cards to new clients</Typography>
                            <Switch
                                checked={sendToNew}
                                onChange={() => changeSendToNew()}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Stack>

                    <VerticalSpacing value="10px"/>
                    <Typography variant="subtitle2">Enabling this will send cards to all newly created gazelle clients that have the status 'Active' or 'New' and have a valid address. 
                        Clients with the status 'Prospect' are not sent cards until they become Active or New. The card template "NOTE Automated New Client Card" will be used. 
                        You can edit this template from the Card Templates page.
                    
                    </Typography>
                    </Card>
                </Stack>
                }
                
                <VerticalSpacing value="30px"/>

                <Stack direction='row' spacing={2}>
                    
                    <Typography variant="h4"  sx={{fontWeight:'bold'}}>NOTE Templates</Typography>
                    
                    {loading &&  
                        <Box sx={{maxWidth:'200px'}}>
                            <Loading text="Loading Templates"/>
                        </Box>
                    }
                    
                </Stack>
                <VerticalSpacing value="10px"/>

                {organization ? <Accordion expanded={expanded} variant={ expanded && "outlined"} elevation={0} onChange={() => handleCreateSelected()}>
                        <AccordionSummary
                            
                            expandIcon={ expanded ? <ClearIcon/> : <AddCircleIcon/>}
                            aria-controls="panel1a-content"
                            sx={expanded ? {
                                backgroundColor: "lightgray",
                                borderRadius: '0px'
                              } : {
                                backgroundColor: "lightgray",
                                borderRadius: '10px'
                              }}
                            id="panel1a-header">

                            <Typography variant='h6' sx={{fontWeight:'bold'}}>{"Create A Template"}</Typography>
                        
                        </AccordionSummary>

                        <AccordionDetails>
                            <Stack direction='row' justifyContent="flex-end">
                                {updated && <Button variant="outlined" onClick={() => createAction()}><Typography variant='body'>{"save"}</Typography></Button>}
                                {<Button variant="contained" onClick={() => clearTemplate()}><Typography variant='body'>{"reset"}</Typography></Button>}
                                
                            </Stack> 

                            <Typography  variant='body'  sx={{fontWeight:'bold'}}>{"Template Name"}</Typography>

                            <TextField
                                fullWidth
                                inputProps={{ maxLength: 150 }}
                                placeholder="Add a name (recipient does not see this)"
                                value={newName}
                                onChange={(e) => handleNameChange(e)}
                            />

                            <VerticalSpacing value='10px'/>

                            <Stack direction='column'>
                                <Typography  variant='body'  sx={{fontWeight:'bold'}}>{"Template Body"}</Typography>

                                <Stack direction='row' spacing={1} alignItems='center'>
                                    <Typography  variant='body'>{"We support all of Gazelles Merge Codes."}</Typography>
                                    <Button variant="outlined"  fullWidth={false} onClick={() => setShowMergeCodesPopup(true)}>Merge Codes</Button>
                                </Stack>
                            </Stack>

                            <VerticalSpacing value='10px'/>

                            <VerticalSpacing value='10px'/>

                            <TextField
                                fullWidth
                                multiline
                                placeholder="Add a Body (500 char max)"
                                inputProps={{ maxLength: 500 }}
                                value={newBody}
                                rows={10}
                                onChange={(e) => handleBodyChange(e)}
                                InputProps={{
                                    endAdornment:(
                                        <InputAdornment sizeSmall variant='filled' position="end">
                                            <Typography color={remainingCharacters === 500 ? "error" : "success"} variant='subtitle2'>
                                                {remainingCharacters + " chars"}
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }}
                            /> 
                            
                        </AccordionDetails>
                </Accordion> :

                    <Typography variant="caption">Link your gazelle account to create and edit templates</Typography>
                }
                <Box>
                    {templates ? <TemplateList sendingOn={organization.sendToNew} data={templates} onDelete={(template) => askDeleteConfirmation(template)} onSave={(template, name, body, subject) => saveAction(template, name, body, subject)}/> : ""}
            
                   </Box>

            </Container>

            <Snackbar open={snackbarOpen()} autoHideDuration={4000} onClose={handleSnackbarClose}>
                {snackbarOpen() ? <Alert variant="filled" severity={actionResults.success ? "success" : "error"}>
                {actionResults.success ? actionResults.success : actionResults.error}
                </Alert> : <div/>}

            </Snackbar>

            {deletingTemplate && <Modal
                open={showDeletePopup}
                onClose={() => setShowDeletePopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >

                <ConfirmationPopup
                    positive="Delete"
                    negative="cancel"
                    handleClose={() => setShowDeletePopup(false)}
                    handlePositive={() => postiveDeleteAction()}
                    title="Delete Template?"
                    body={deletingTemplate.name}
                />

            </Modal>}

            <Modal
                open={showMergeCodesPopup}
                onClose={() => setShowMergeCodesPopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >

                <MergeCodesPopup
                    isNewClient={false}
                    handleClose={() => setShowMergeCodesPopup(false)}
                />

         </Modal>
        </Container>


    );
};

export default Templates;