import "./App.css";

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import {TopNavigationBar} from './Components/Common/TopNavigationBar';
import CssBaseline from "@mui/material/CssBaseline";
import { MyThemeProvider } from "./Theme";
import { FirebaseAppProvider, useFirebase } from "./FirebaseApp";

import Subscription from "./Components/Billing/Subscription"
import Dashboard from "./Components/Dashboard/Dashboard"
import Cards from "./Components/CardHistory/Cards"
import Account from "./Components/AccountSettings/Account"
import UpdateEmail from "./Components/Onboarding/UpdateEmail";
import Register from "./Components/Onboarding/Register"
import Templates from "./Components/Templates/Templates"
import Login from "./Components/Onboarding/Login"
import Splash from "./Components/Onboarding/Splash"
import Reset from "./Components/Onboarding/Reset"
import FAQ from "./Components/FAQ/FAQ"
import Introduction from "./Components/Introduction/Introduction"
import Success from "./Components/Common/Success"
import Contact from "./Components/Contact/Contact";




export default function AppWithFirebase() {

  return (
    <FirebaseAppProvider>
      <MyThemeProvider>
        <CssBaseline/>
        <App />
      </MyThemeProvider>
    </FirebaseAppProvider>
  );
}





function App() {
    
  const { user } = useFirebase()

  useEffect(() => {
    
  }, [user]);

  return (

      <div className="app">

        <Router>
      
            <Routes>

              <Route path="/billing" element={user && <Subscription/>}/>
              <Route path="/cards" element={user && <Cards/>}/>
              <Route path="/subscription" element={user && <Subscription/>}/>
              <Route path="/account" element={user && <Account/>}/>
              <Route path="/home" element={user && <Dashboard/>}/>
              <Route path="/dashboard" element={user && <Dashboard/>}/>
              <Route path="/templates" element={user && <Templates/>}/>
              <Route path="/contact" element={user && <Contact/>}/>
              <Route path="/support" element={user && <Contact/>}/>
              <Route path="/intro" element={<Introduction/>}/>
              <Route path="/faq" element={<FAQ/>}/>
              <Route path="/success" element={<Success/>}/>
              <Route path="/register" element={<Register/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/reset" element={<Reset/>}/>
              <Route path="/emailupdate" element={<UpdateEmail/>}/>
              <Route path="/welcome" element={<Splash/>}/>
              <Route path="/" element={<Splash/>}/>

            </Routes>

        </Router>
      
    </div>
  );
}


