
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

export function VerticalSpacing({value}) {

  return (
    <Box
    sx={{
      width: '100%',
      height: value
    }}
  />

  );
}
export function HorizontalSpacing({value}) {

  return (
    <Box
    sx={{
      width: value,
      height: '100%'
    }}
  />

  );
}

export function ThinLine({width}) {

  return (
    <Box
      sx={{
        width: '100%',
        height: 1,
        backgroundColor: 'primary.dark'
      }}
    />

  );
}


export function ListButton({onClick, title, icon}) {
  return (
    <Box>
      <Container sx={{  
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '7vh',
        cursor:'pointer',
        textTransform: 'none',
        width: '100%'}}
        onClick={() => onClick()}>

        {icon && icon}

        <Button
          style={{textTransform: 'none'}}
          variant="text"
        >
          <Typography color='common.black' variant="button">{title}</Typography>
        </Button>

        <ChevronRightIcon color='primary'/>
      </Container>
      <ThinLine/>
    </Box>
  );
}

export function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://pianonotes.app/">
        NOTE
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}