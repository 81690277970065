import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";


import Loading from "../Common/Loading"
import SubscriptionPopup from "../Common/SubscriptionPopup"

import { useFirebase } from "../../FirebaseApp";
import bgImage from "../../Assets/girl_piano_bw.webp"

import { Box, Button, Card, Container, Modal, Stack, Typography } from "@mui/material";
import { VerticalSpacing } from "../Common/Common";
import { useNote } from "../../hooks/useNote";
import { BillingHistoryTable } from "./BillingHistoryTable";
import { TopNavigationBar } from "../Common/TopNavigationBar";


function Subscription(){

    const [paymentData, setPaymentData] = useState(undefined)
    const [loadingUrl, setLoadingUrl] = useState(false)
    
    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false)
    const [products, setProducts] = useState(null)
    const [productType, setProductType] = useState('product')
    
    const [isSubscribed, setIsSubscribed] = useState(false);    
    const {user} = useFirebase();
    const {userData, loading, organization, getBillingHistory, getProducts, basePricingInfo, getStripePaymentPage,  createPortalUrl, getStripeMeteredSubscriptionPaymentPage} = useNote()
    const navigate = useNavigate();
    
    
    useEffect(() => {
        
        if(loading) return;
        if (!user) return navigate("/welcome", {replace:true});

                
        if(products == null){
            fetchProducts()
        }

        if(paymentData === undefined && userData != null && userData.companyId !== "") {
            loadPaymentHistory()
        }

        if(organization != null){
            checkSubscribed()
        }


        
        
    }, [user, userData, organization, products, paymentData, isSubscribed, basePricingInfo]);



    async function fetchProducts(){
        const products = await getProducts()
        setProducts(products)
    }


    async function loadPaymentHistory(){
        const new_payments = await getBillingHistory(userData.companyId)
        setPaymentData(new_payments)
        
    }

    
    const handlePurchase = async(purchase) =>{

        let price_id = purchase.price_id
        
        var url = await getStripeMeteredSubscriptionPaymentPage(price_id, userData.uid, userData.companyId, organization.stripeClientId)

        window.location.assign(url);
    }

    const openInvoiceUrl = (url) =>{
        if(url !== undefined && url !== ""){
                    
            window.open(url)
        }
    }
    

    
    const manageSubscription = async() =>{
        
        if(isSubscribed){
            
            setLoadingUrl(true)
            //create a portal session and open for the user
            if(organization != null){
                
                let stripeCustomerId = organization.stripeClientId
                
                let url = await createPortalUrl(stripeCustomerId)
                
                if(url !== undefined && url !== ""){
                    
                    window.location.assign(url)
                }
            }
           
        }else{
            
            setProductType('metered_subscription')
            setShowSubscriptionPopup(true)
        }
    }
    

    const getCostPerCard = () =>{

        if(products == null || basePricingInfo == null){
            return 4.38
        }

        let users = basePricingInfo.at_cost_users
        let postage = Number(basePricingInfo.base_cost)


        let cost1
        let cost2
        for(const prod of products){
            if(prod.id === 'acs'){
                cost1 = prod.base_cost

            }else if (prod.id === 'mms'){
                cost2 = prod.base_cost
            }
        }

        let cardCost = Number(cost2)
        if(users.includes(organization.companyId)){
            cardCost = Number(cost1)
        }

        
        return cardCost + postage
    }
    
    

    const cardsSent = () => {
    
        if(organization == null || organization.cardsSent == null || organization.cardsSent < 1){
            return "0"
        }
        let cs = organization.cardsSent 

        return cs
    }

    const approxBill = () =>{
        if(organization == null || organization.cardsSent == null || organization.cardsSent < 1){
            return "$0"
        }
        let cs = organization.cardsSent 
        let cpc = getCostPerCard()
        let total = cpc * cs

        return "$" + +total.toFixed(2)
    }
    
    
    
    const billingText = () => {

        if(organization == null){
            return ""
        }
        
        let expires = organization.subscriptionExpireTime
        
        if(!isSubscribed){
            
            return "No Current Subscription"
            
        }else{
            
            var date = new Date(0);
            date.setSeconds(expires); // specify value for SECONDS here

            //months start at 0 for january
            var month = date.getMonth() + 1
            var day = date.getDate();
            var year = date.getFullYear();
            var shortStartDate = month + "/" + day + "/" + year;

            return shortStartDate
        }
    }
    
    
    const checkSubscribed = () => {

        if(organization == null){
            return
        }
        
        let now = new Date().getTime() / 1000
        let expires = organization.subscriptionExpireTime + 86400
        
        if(Number(Math.trunc(now)) > Number(expires)){
           setIsSubscribed(false)
           return
        }
        setIsSubscribed(true)
    }



    return (
            <Container className="main-container" maxWidth={false}sx={{
                padding:"10px",
                  backgroundImage: `url(${bgImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundAttachment: "fixed",
                  maxHeight:'100%',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}>
                { user && <TopNavigationBar/>}    
                <Container maxWidth='sm' disableGutters={true} sx={{paddingTop:'100px', paddingLeft:'10px',paddingRight:'10px', background:'#ffffff', borderRadius: 2}}>

                    <Typography variant="h5"  sx={{fontWeight:'bold'}}> Subscription </Typography>

                    {!organization && <Typography variant="caption" >{"Link your gazelle account to view subscription details"}</Typography>}
                    {isSubscribed && <Card variant="outlined" sx={{padding:'5px'}}>

                            <Stack direction='row' spacing={1} >
                                
                                <Typography variant="body2" >{"Next Billing Cycle "}</Typography>
                                <Typography  variant="body2"  style={{fontWeight:'bold'}}>{billingText()}</Typography>

                            </Stack>

                            <VerticalSpacing value='10px'/>

                            <Stack direction={{xs:'column', sm:'row'}} justifyContent='space-between' spacing='10px'>

                                <Stack direction='row' spacing={1}>
                                    <Typography variant="body2">{"Cards sent this cycle"}</Typography>
                                    <Typography variant="body2"  style={{fontWeight:'bold'}}>{cardsSent()}</Typography>
                                </Stack>

                                <Stack direction='row' spacing={1}>
                                
                                    <Typography variant="body2">{"Estimated Bill "}</Typography>
                                    <Typography variant="body2"  style={{fontWeight:'bold'}}>{approxBill()}</Typography>
                                </Stack>
                            </Stack>

                            <VerticalSpacing value={10}/>
                    </Card>}

                    <VerticalSpacing value='10px'/>

                    {organization && <Button variant="outlined" fullWidth  onClick={() => manageSubscription()}>{isSubscribed ? "Manage Subscription" : "Purchase Subscription"}</Button>}
                    {loadingUrl &&  <Loading text="Loading Subscription Portal"/>}

                    <VerticalSpacing value='20px'/>
                    <Typography variant="body">History</Typography>

                    {loading &&
                        <Box sx={{maxWidth:'200px', width:'33%'}}>
                            <Loading text="Loading Billing History"/>
                        </Box>
                    }

                    <Container maxWidth='sm' disableGutters={true}>
                            {((paymentData === undefined || paymentData.length === 0) && !loading) && <Typography variant="caption">No Recent Invoices</Typography>}
                            {(paymentData && paymentData.length > 0) && <BillingHistoryTable bills={paymentData} handleUrl={(url) => openInvoiceUrl(url)}/>}
                            
                    </Container>

                </Container>

                <Modal
                    open={showSubscriptionPopup}
                    onClose={() => setShowSubscriptionPopup(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">

                    <SubscriptionPopup

                        postage={basePricingInfo ? basePricingInfo.postage : 0.63}
                        products={products} 
                        handleCallback={(data) => handlePurchase(data)} 
                        handleClose={() => setShowSubscriptionPopup(false)}/>
                </Modal>
                    
            </Container>

    );
};



export default Subscription; 