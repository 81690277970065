import { Button, Container, Stack, Typography, Box, Link } from "@mui/material";
import  { useState } from "react";
import { VerticalSpacing } from "./Common";
import { useNavigate } from "react-router";
import AsyncImage from "../Common/AsyncImage"
import Logo from "../../Assets/logo_transparent_background.png"

function Success() {
  const navigate = useNavigate();

  const toCardTemplates = () =>{
    navigate("/templates", {replace:true}); 
  }
  const backToBilling = () =>{
    navigate("/billing", {replace:true}); 
  }

  const toDashboard = () =>{
    navigate("/home", {replace:true}); 
  }
  return (
    <Container maxWidth={false}>
      
      <Container maxWidth='md'>
        <VerticalSpacing value="100px"/>

        <Box   sx={{cursor:'pointer', height:'80px' }} onClick={() => toDashboard()} >
              <AsyncImage padding={0} height='80px' src={Logo}></AsyncImage>

            </Box>

        <Stack direction='column'>
            <Typography variant="h3"> Congratulations! </Typography>
            <Typography variant="body2"> Subscription Successful. You are a few clicks away from impressing your clients like never before.  </Typography>

            <VerticalSpacing value="20px"/>
            <Typography Typography variant="h6">We have created the following default templates and synced them to your Gazelle account.</Typography>
            <VerticalSpacing value="5px"/>
  
            <Typography Typography variant="body2">- NOTE First Time Client Card</Typography>
            <Typography Typography variant="body2">- NOTE Appointment Completed Card</Typography>
            <Typography Typography variant="body2">- NOTE Automated New Client Card</Typography>
            <VerticalSpacing value="5px"/>
            <Link sx={{cursor:'pointer'}} onClick={() => toCardTemplates()}>To Card Templates</Link>


            <VerticalSpacing value="20px"/>

            <Typography variant="h5">To send a card select any client in Gazelle, schedule a custom message using one of the NOTE templates, and start creating lifetime clients. </Typography>
            <Typography variant="body2">Reach out to support@pianonotes.app if you have any questions. </Typography>

            <VerticalSpacing value="20px"/>

            <Stack direction='row' spacing={5}>
            <Link sx={{cursor:'pointer'}} onClick={() => toDashboard()}>Home</Link>

              <Link sx={{cursor:'pointer'}} onClick={() => backToBilling()}>Subscriptions</Link>
              
            </Stack>

        </Stack>
      </Container>
        
    </Container>
  );
}

export default Success;
