import React from "react";
import UserModel from "../Models/UserModel";
import Organization from "../Models/Organization";
import { useFirebase } from "../FirebaseApp";
import { onSnapshot, addDoc, collection, getDocs, getDoc, doc, query, setDoc, orderBy, limit, updateDoc } from "firebase/firestore"
import { httpsCallable } from "firebase/functions";
import { getAuth, updateEmail } from "firebase/auth";


export function useNote() {
  const {user, database, functions } = useFirebase();
  let auth = getAuth()
  const NO_ADDRESS_COLLECTION = 'NoAddressNewClients'
  const USERS_COLLECTION = 'Users'
  const PROSPECTS_COLLECTION = 'ProspectClients'
  const ORGANIZATION_INFORMATION_COLLECTION = 'OrganizationInformation'
  const SUPPORT_REQUESTS_COLLECTION = 'SupportRequests'
  const CUSTOMIZED_TECH_COLLECTION= 'CustomizedTechnicians'
  const CARD_HISTORY_COLLECTION = 'CardHistory'
  const BILLING_HISTORY_COLLECTION = 'BillingHistory'
  const FAQ_COLLECTION = "FAQ"

  const [loading, setLoading] = React.useState(false)
  const [userData, setUserData] = React.useState(null);
  const [organization, setOrganization] = React.useState(null);

    const [basePricingInfo, setBasePricingInfo] = React.useState(null)

  /**************************************************
   * ****************************************************************
  *  USE EFFECT
  * ****************************************************************
  * **************************************************/
  // Fetch all todos on load and whenever our graphql client changes (i.e. either the current user OR App ID changes)
  React.useEffect(() => {

    if(user){

      fetchProfiles()
      
    }

  }, []);
  // Fetch all todos on load and whenever our apolloClient client changes (i.e. either the current user OR App ID changes)
  
  /******************************************
  *  END -- USE EFFECT
  * **************************************/






  /******************************************
  * ***************************************
  *  START -- ALL PROFILE  QUERY's/MUTATION
  * ****************************************
  * **************************************/

    const fetchProfiles = async() =>{
        setLoading(true)

        await getProfile(user.uid)

        setLoading(false)
    }


    async function getProfile(id){

        let ud = await getUserData(id)
        if(ud && ud.companyId !== ""){
            getOrganization(ud.companyId)

        }
    }

    const checkSubscribed = () => {

        if(organization == null){
            return false
        }
        
        let now = new Date().getTime() / 1000
        //add a day of grace
        let expires = organization.subscriptionExpireTime + 86400
        
        if(Number(Math.trunc(now)) > Number(expires)){
           
           return false
        }
        return true
    }
    


    async function getUserData(id){

        if(userData != null){
            return userData
        }else {
            setLoading(true)

            let user =  await fetchUserOnce(id)

            setUserData(user)

            setLoading(false)

            return user
        }
    }


    async function getOrganization(companyId){

        if(companyId === undefined || companyId === ""){
            return undefined
        }
        
        if(organization != null){
            return organization;
            
        }else{
            setLoading(true)
            
            let org = await fetchOrganizationOnce(companyId)
            setOrganization(org) 
            checkSubscribed()
            setLoading(false)
            
            return org
        }
    }


    async function fetchUserOnce(id) {

        setLoading(true)

        let userRef = doc(database, "Users", id)
        let document = await getDoc(userRef)

        const data = document.data();
        let user
        if(document !== undefined && data !== undefined){
            user = new UserModel(data)
        }  else{

            user = new UserModel(undefined)
        }
        setLoading(false)

        return user
        
        
    };

    async function fetchOrganizationOnce(companyId) {       
        setLoading(true)
    
        let orgRef = doc(database, "OrganizationInformation", companyId)
        let document = await getDoc(orgRef)
            
        let data
        if(document != null && document.data() != null){
            
            data = document.data();
        } 
        
        setLoading(false)
        return data
    };


    //this attatches a listener to listen when user data changes
    async function attatchUserListener(id) {

        try {
            let userRef = doc(database, "Users", id)
            let ul = await onSnapshot(userRef, (document) => {

                const data = document.data();
            
                if(doc !== undefined && data !== undefined){
                    
                    let user = new UserModel(data)
                    
                    setUserData(user)
                    

                }else{

                    setUserData(undefined)
                }                
                
            });

            return ul
                                   
        } catch (err) {
            console.error(err);
            setUserData(undefined)
        }
    };



    async function attatchOrganizationListener() {
        
        if(userData == null){
            return;
        }
        
        let companyId = userData.companyId
        
        if(companyId == "" || companyId == null){
            return;
        }

        let orgRef = doc(database, "OrganizationInformation", companyId)
        try {
            
            let ol = await onSnapshot(orgRef, (document) => {

                const data = document.data();

                
                if(document !== undefined && data !== undefined){
                    
                    let org = new Organization(data)
                    setOrganization(org)

                }else{
                    setOrganization(undefined)
                }                
                
            });        
                                                
        } catch (err) {

            console.error(err);
            setOrganization(undefined)
        }
    };

    const submitSupportRequest = async (request) =>{
            
        setLoading(true)
        let timestamp = new Date()

        let result = {success:true, message:"Request Sent"}
        var newRequest = {
            created: timestamp,
            name: request.name,
            message:request.message,
            email: request.email,
            uid:request.uid
        }
        
        try {
            let res = await addDoc(collection(database, SUPPORT_REQUESTS_COLLECTION), newRequest)
            return result

        } catch (e) {
            console.error(e); // handle your error here
            result.success = false
            result.message = e
        } 

        setLoading(false)

        return result
    }

    const getFonts = async() =>{
        setLoading(true)

    try {
        let fPath = collection(database, "Fonts")
        const fonts = await getDocs(fPath)
        

        let all_fonts = {}

        fonts.forEach(doc =>{

            let data = doc.data()
            let id = data.id
            
            all_fonts[id] = data

        })
        setLoading(false)
        
        return all_fonts

    } catch (err) {
        setLoading(false)

        console.error(err);
        alert(err.message);
    }

    };

    const getCards = async() =>{

    try {
            setLoading(true)

            let cPath = collection(database, "Cards")
            const cards = await getDocs(cPath)
            
            let all_cards = {}


            cards.forEach(doc =>{

                let data = doc.data()
                let id = data.id
                all_cards[id] = data

            })
            setLoading(false)
            
            return all_cards
        
    } catch (err) {
            setLoading(false)

            console.error(err);
            alert(err.message);
    }


    };


    const getFontsAndCards = async() => {
        setLoading(true)
    
        let cards = await getCards()
        let fonts = await getFonts()
        
        let final = {fonts:fonts, cards:cards}

        setLoading(false)

        return 

    }


    const getBillingHistory = async(companyId) => {
    
        if(companyId === undefined || companyId === ""){
            return "CompanyId parameter is undefined"
        }
        setLoading(true)
    
    
        const billingHistoryRef = collection(database, ORGANIZATION_INFORMATION_COLLECTION, companyId, BILLING_HISTORY_COLLECTION);

        // Create a query against the collection.
        const q = query(billingHistoryRef, orderBy('timestamp', 'desc'), limit(12));
        
        const querySnapshot = await getDocs(q)

        var new_payments = [];
            
        querySnapshot.forEach((doc) => {
            
            var data = doc.data()
            let date = data.timestamp
            var id = doc.id
            if(date !== undefined){
                data.timestamp = date.toDate().getTime()
            }else{
                data.timestamp = undefined
            }
            data.id = id
            
            new_payments.push(data);
        });

        setLoading(false)
        
        return new_payments
    
    }
    
    const getCardHistory = async(companyId, size) => {
    
        if(companyId === undefined || companyId === ""){
            return "CompanyId parameter is undefined"
        }
        
        setLoading(true)
        
        
        const cardHistoryRef = collection(database, ORGANIZATION_INFORMATION_COLLECTION, companyId, CARD_HISTORY_COLLECTION);

        // Create a query against the collection.
        const q = query(cardHistoryRef, orderBy('send_date', 'desc'), limit(size));
        
        const querySnapshot = await getDocs(q)

        var new_sent = [];
        
        querySnapshot.forEach((doc) => {

            var data = doc.data()
            var id = doc.id
            
            let date = data.send_date
        
            
            if(date !== undefined){
                data.send_date = date.toDate().getTime()
            }else{
                data.send_date = undefined
            }
            data.id = id
            new_sent.push(data);
        });
            setLoading(false)
            
        return new_sent
    
    }

    const updateOrganization = async(data)=>{
    
        if(data.companyId === undefined || data.companyId === ""){
            return "CompanyId parameter is undefined"
        }
        setLoading(true)
        const orgRef = doc(database, ORGANIZATION_INFORMATION_COLLECTION, data.companyId);
        
        try {
            await setDoc(orgRef, data, { merge: true })
        } catch (e) {
            console.error(e); // handle your error here
            setLoading(false)

            return false
            
        }

        //return new org
        
        let newOrg = Object.assign({}, organization, data);
        setOrganization(newOrg)

        setLoading(false)


        
        return true
    }


    const updateUserData = async(data, uid)=>{
    
        if(uid === undefined || uid === ""){
            return false
        }

        setLoading(true)
        const userRef = doc(database, USERS_COLLECTION, uid);
        
        try {
            await setDoc(userRef, data, { merge: true })
        } catch (e) {
            console.error(e); // handle your error here
            setLoading(false)

            return false            
        }

        setLoading(false)
        return true
    }


    const updateUserEmail = async(email)=>{
    
        if(auth.currentUser === undefined || auth.currentUser === ""){
            return "User is undefined"
        }
        const userRef = doc(database, USERS_COLLECTION, auth.currentUser.uid);

        setLoading(true)
        
        try {
            await updateEmail(auth.currentUser, email)
            await updateDoc(userRef, {email:email})

        } catch (e) {
            console.error(e); // handle your error here

            return false
            
        }
        setLoading(false)

        
        return true
    }
        
    const sendFreeCardOnce = async(data)=>{
    
        if(data.companyId === undefined || data.companyId === ""){
            return "CompanyId  is undefined"
        }
        setLoading(true)
        
        const sendFreeCard = httpsCallable(functions,'sendFreeCard');
            
        let result = await sendFreeCard(data)
        const resdata = result.data;

        if(resdata.success == true){
            let newOrgData = organization
            newOrgData.usedFreeCard = true
            setOrganization(newOrgData)
        }

        setLoading(false)

        return resdata.success
    }

    const getTemplates = async(data)=>{
    
        if(data.companyId === undefined || data.companyId === ""){
            return "CompanyId  is undefined"
        }
        setLoading(true)
        
        const getAll = httpsCallable(functions,'getCallTemplates');
            
        let result = await getAll(data)
        const resdata = result.data;


        setLoading(false)

        return resdata.data
        
    }

    const updateTemplate = async(data)=>{
    
        if(data.companyId === undefined || data.companyId === ""){
            return "CompanyId  is undefined"
        }
        
        if(data.body === undefined || data.body === ""){
            return "template  is undefined"
        }

        if(data.name === undefined || data.name === ""){
            return "name  is undefined"
        }

        if(data.templateId === undefined || data.templateId === ""){
            return "templateId  is undefined"
        }

        if(data.subject === undefined || data.subject === ""){
            return "subject  is undefined"
        }


        setLoading(true)

        const updateTemplate = httpsCallable(functions,'updateTemplate');
            
        let result = await updateTemplate(data)
        const resdata = result.data;

        setLoading(false)

        return resdata 
    }

    const createTemplate = async(data)=>{
    
        if(data.companyId === undefined || data.companyId === ""){
            return "CompanyId  is undefined"
        }
        if(data.body === undefined || data.body === ""){
            return "body  is undefined"
        }

        if(data.name === undefined || data.name === ""){
            return "name  is undefined"
        }

        setLoading(true)

        const createTemplate = httpsCallable(functions,'createTemplate');
            
        let result = await createTemplate(data)
        const resdata = result.data;

        setLoading(false)

        return resdata 
    }

    const deleteTemplate = async(data)=>{
    
        if(data.companyId === undefined || data.companyId === ""){
            return "CompanyId  is undefined"
        }

        if(data.templateId === undefined || data.templateId === ""){
            return "tempateId  is undefined"
        }
        setLoading(true)
        
        const deleteTemplate = httpsCallable(functions,'deleteTemplate');
            
        let result = await deleteTemplate(data)
        const resdata = result.data;

        setLoading(false)

        return resdata 
    }


    const getSubscriptionProducts = async() => {
        setLoading(true)

        try {
            
            const productsPath = doc(database, "Pricing", "Stripe")
            
            const products = await getDoc(productsPath)
            
            if(products === undefined || products.data() === undefined){
                return false;
            }
            
            let product_arr = Object.values(products.data())
            
                setLoading(false)
            
            return product_arr
            
        } catch (err) {
                setLoading(false)

            console.error(err);
            console.log("Issue retrieving subscription products")
        }

    }



    const getProducts = async() =>{

        setLoading(true)

        try {
            
            const productsPath = doc(database, "Pricing", "Stripe")
            
            const products = await getDoc(productsPath)
            
            if(products === undefined || products.data() === undefined){
                return false;
            }



            
            let product_arr = Object.values(products.data())

            let live_products = []
            for(const prod of product_arr){
                let type = prod.type
                if(type === 'metered_subscription'){
                    live_products.push(prod)
                }else if(type === 'info'){
                    setBasePricingInfo(prod)
                }
            }
            
            setLoading(false)
            
            return live_products
            
        } catch (err) {

            setLoading(false)

            console.error(err);
            console.log("Issue retrieving subscription products")
        }

    }


    const getFAQs = async() =>{

        setLoading(true)
        var faqs = [];

        try {

            const faqRef = collection(database, FAQ_COLLECTION);

            // Create a query against the collection.
            const q = query(faqRef, limit(10));
            const querySnapshot = await getDocs(q)

            querySnapshot.forEach((doc) => {

                
                var data = doc.data()
                
                faqs.push(data)
            
            });
            
        } catch (err) {

            setLoading(false)

            console.error(err);
            console.log("Issue retrieving subscription products")
        }

        return faqs

    }


    const getStripeMeteredSubscriptionPaymentPage = async(priceId, uid, companyId, stripeId) =>{

        if(uid === undefined || uid === ""){
            return "UID parameter is undefined"
        }
        setLoading(true)

        let sub_data = {price_id: priceId, company_id: companyId, uid:uid, customer_id:stripeId}
        const createMeteredSubscriptionSession = httpsCallable(functions, 'createMeteredSubscriptionSession');
            
        let result = await createMeteredSubscriptionSession(sub_data)
        const data = result.data;

        setLoading(false)

        return data.url
    }


    
    const createPortalUrl = async( customerId) =>{
        
        if(customerId === undefined || customerId === ""){
            return "customerId parameter is undefined"
        }
        setLoading(true)
        
        const createPortalSessionUrl = httpsCallable(functions,'createPortalSessionUrl');
            
        let result = await createPortalSessionUrl({customerId: customerId})

        const data = result.data;

        setLoading(false)

        return data.url
    }



    return {
        userData,
        organization,
        loading,
        checkSubscribed,
        updateUserEmail,
        getBillingHistory, 
        createPortalUrl,
        getSubscriptionProducts, 
        getProducts,
        getTemplates,
        updateTemplate,
        createTemplate,
        deleteTemplate,
        basePricingInfo,
        getStripeMeteredSubscriptionPaymentPage,
        submitSupportRequest,
        attatchOrganizationListener,
        attatchUserListener,
        getUserData,
        getOrganization,
        fetchOrganizationOnce,
        fetchUserOnce,
        updateOrganization,
        sendFreeCardOnce,
        getFAQs,
        updateUserData,
        getCardHistory

    };
}
