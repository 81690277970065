import {styled} from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import { Button, Container, Stack, Typography } from "@mui/material";
import { VerticalSpacing } from "./Common";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 6
};

    



const ConfirmationPopup = ({positive, negative, title, body, handleClose, handlePositive}) => {
    
  
    return (
        

 
                    
            <Container maxWidth='sm' sx={style}>
                    <Typography variant="h4">{title}</Typography>
                    <VerticalSpacing value='5px'/>
                    <Typography style={{whiteSpace: 'pre-line'}} variant="body2">{body}</Typography>
                                     
                    <VerticalSpacing value='20px'/>
                    <Stack direction='row' justifyContent='space-between'>
                        {negative !== undefined && <Button variant="outlined" onClick={() => handleClose()}>{negative}</Button>}
                        {positive !== undefined && <Button variant="contained" onClick={() => handlePositive()}>{positive}</Button>}
    
                    </Stack>

                    
            </Container>
    );
}
    
export default ConfirmationPopup;