import React, { useEffect, useState } from "react";


import { useNavigate } from "react-router";
import { useFirebase } from "../../FirebaseApp"; 
import Loading from "../Common/Loading"
import { Button, Box, Container, Typography, Stack } from "@mui/material";
import bgImage from "../../Assets/girl_piano_bw.webp"

import { useNote } from "../../hooks/useNote";
import { CardHistoryTable } from "./CardHistoryTable";
import { VerticalSpacing } from "../Common/Common";
import { TopNavigationBar } from "../Common/TopNavigationBar";


function Cards() {

    const {user} = useFirebase();
    const {userData, organization, loading, getCardHistory} = useNote();
    const navigate = useNavigate();
    

    const exampleMesasge = "Hello, \n\nSo happy you can join us! This is where you will see card messages that have been picked up by our system and sent to the recipient. If you have any questions feel free to visit our faq page at https://pianonotes.app/faq or the how to walkthrough at https://pianonotes.app/intro \n\n Best Wishes,\nNOTE Automated Cards "
    const exampleCards = [{address:"101 Example Way", order_id:'012093823', recipient:"John \"Set An\" Example", send_date:new Date(), type:'example', message:exampleMesasge}]
    
    const [cardHistory, setCardHistory] = useState([])
    const [filteredCards, setFilteredCards] = useState([])

    const [showLoadMore, setShowLoadMore] = useState(true)
    const [initialCardsRetrieved, setInitialCardsRetrieved] = useState(false)

    const [size, setSize] = useState(50)
    const pageSize= 50
    
    useEffect(() => {
        if(loading)return;
        if (!user) return navigate("/welcome", {replace:true});
        
        if(!initialCardsRetrieved && userData != null && userData.companyId !== "") {
            setInitialCardsRetrieved(true)
            loadCardHistory(size)
        }
        
    }, [user, loading, userData, cardHistory, filteredCards, showLoadMore]);


    
    async function loadCardHistory(size){

        const new_sent = await getCardHistory(userData.companyId, size)

        if(new_sent.length < size){
            setShowLoadMore(false)
        }
        if(new_sent.length > 0){
            setCardHistory(new_sent)
            setFilteredCards(new_sent)
        }else{
            setCardHistory(exampleCards)
            setFilteredCards(exampleCards)
        }
    }



    async function loadNextBatch(){

        if(cardHistory.length < pageSize || cardHistory.length < size){
            setShowLoadMore(false)
            return
        }
        let newSize = size + pageSize
        setSize(newSize)
        loadCardHistory(newSize)
    }


    const handleSearch = (value) => {
        
        let filtered = cardHistory.filter(card => (card.recipient.includes(value) || card.address.includes(value) || card.order_id.toString().includes(value)));
        setFilteredCards(filtered)
    }



    return (
        <Container className="main-container" maxWidth={false} sx={{
              backgroundImage: `url(${bgImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: "fixed",
              maxHeight:'100%',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
             { user && <TopNavigationBar/>}   

            <Container maxWidth='md' disableGutters sx={{paddingTop:'10px', paddingLeft:'10px',paddingRight:'10px',borderRadius: 2, background:'#ffffff'}}>
                    
                    <Typography variant="h5"  sx={{fontWeight:'bold'}}>Card History</Typography>

                    <VerticalSpacing value='24'/>

                    {cardHistory && <Stack spacing={2} direction='row' justifyContent='end' alignItems="center">
                           <Typography variant="subtitle2">{showLoadMore  ? "Searching the " + cardHistory.length +  " most recent cards" : "Entire Card History loaded"}</Typography>
                            {showLoadMore && <Button variant='outlined' onClick={() => loadNextBatch()}>Load more</Button>}
                        </Stack>}

                    <VerticalSpacing value='24'/>

                    {loading &&  
                        <Box sx={{maxWidth:'200px'}}>
                            <Loading text="Loading Card History"/>
                        </Box>
                    }
                    
                    <Box>
                        {(filteredCards !== null  && !loading) && <CardHistoryTable cards={filteredCards} onSearch={(value) => handleSearch(value)}/>}
                    </Box>

            </Container>
        </Container>
    );
};

export default Cards;