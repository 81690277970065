import React from "react";
import {initializeApp} from "firebase/app";
import {getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, signOut, updateEmail} from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFunctions } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import  { getFirestore, doc,  setDoc} from "firebase/firestore";
import  { getAnalytics, setUserId } from "firebase/analytics";


const FirebaseAppContext = React.createContext(null);

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};



function createFirebaseApp() {
  return initializeApp(firebaseConfig);
}



export function FirebaseAppProvider({ children }) {

    // Store Firebase.App in React state. If appId changes, all children will rerender and use the new realmApp.
    const [firebaseApp, setFirebaseApp] = React.useState(createFirebaseApp());
    const [database, setDatabase] = React.useState(getFirestore(firebaseApp))
    const [functions, setFunctions] = React.useState(getFunctions(firebaseApp))
    const [analytics, setAnalytics] = React.useState(getAnalytics(firebaseApp))
    const [auth, setAuth] = React.useState(getAuth(firebaseApp))
    const [user, loading] = useAuthState(auth);


    React.useEffect(() => {
        let app = createFirebaseApp()
        // window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
        
        initializeAppCheck(app, {
            provider: new ReCaptchaV3Provider('6LeRGP0fAAAAABzYbiqVCv0x0Ha6MwDqL2Ms2Dgx'),
            isTokenAutoRefreshEnabled: true
        });

        setDatabase(getFirestore(app))
        setFirebaseApp(app);
        setFunctions(getFunctions(app))
        setAnalytics(getAnalytics(app))

        if(user && user.uid){
            setUserId(analytics, user.uid);
        }

    }, [user]);


    const signInWith = React.useCallback(
        async (email, password) => {
    
        var result = {success: true, message:"User logged in"}
        
        try {
            await signInWithEmailAndPassword(auth, email, password);
            
        } catch (err) {
            console.error(err);
            
            result.success = false
            result.message = err.message
        }
        
        return result
    }, [auth]
    );

    const registerWithEmailAndPassword = React.useCallback(
        async (name, email, password) => {
    
        var result = {success: true, message:"User account created"}
        
        try {
            
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

    
            await writeNewUser(user, name, 'email')
            
        } catch (err) {
            console.error(err);
            
            result.success = false
            result.message = err.message
        }
        
        return result
    }, [auth, user]
    );
    
    const sendResetEmail = async(email) => {
        
        var result = {success: true, message:"User account created"}
        
        try {
            await sendPasswordResetEmail(auth, email);
           return result
        } catch (err) {
            console.error(err);
            
            result.success = false
            result.message = err.message;

            return result
        }
    };



    const writeNewUser = React.useCallback(
        async (user, name, provider) =>{
    
        let timestamp = new Date()
    
        var newUser = {
            created: timestamp,
            name: name,
            email: user.email,
            uid: user.uid,
            companyName: "",
            companyId:"",
            authProvider:provider
        }
    
        const updatePath = doc(database, "Users", user.uid);
        
        var success = false

       

        
        try {
            await setDoc(updatePath, newUser)

            success = true
        } catch (e) {
            console.error(e); // handle your error here
            success = false  
        }

        
        return success

    },[database]);
    
    


    const logout = React.useCallback(
        async () => {

            await auth.signOut();
            return user

    }, [auth, user]);





  // Override the App's currentUser & logIn properties + include the app-level logout function
  const firebaseAppContext = React.useMemo(() => {
    return { ...firebaseApp, user, database, auth, loading, functions, analytics, signInWith, sendResetEmail, registerWithEmailAndPassword,  
         logout};
  }, [firebaseApp, user, database, auth, loading, functions, analytics, signInWith, sendResetEmail, registerWithEmailAndPassword, logout  ]);



  return (
    <FirebaseAppContext.Provider value={firebaseAppContext}>
      {children}
    </FirebaseAppContext.Provider>
  );

}



export function useFirebase() {
  const firebaseApp = React.useContext(FirebaseAppContext);
  if (!firebaseApp) {return undefined}
  return firebaseApp;
}
